/* eslint-disable react-hooks/exhaustive-deps */
import {
  ClearAllOutlined,
  DocumentScanner,
  MapOutlined,
  NoteAltOutlined,
  QuizOutlined,
  Upload,
} from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { useFormik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { StandardCard } from "../../components/Cards";
import { PageContainer } from "../../components/container/PageContainer";
import LoadingDialog from "../../components/Dialogs/LoadingDialog";
import {
  ControlledCustomSelect,
  CustomFormLabel,
} from "../../components/formsComponents";
import { PageLoader } from "../../components/PageLoader/PageLoader";
import { TabPanel } from "../../components/Tabs/TabPanel";
import { TabsList } from "../../components/Tabs/TabsList";
import { ITab } from "../../components/Tabs/TabsList.types";
import { TextFieldWithLabel } from "../../components/TextFieldWithLabel/TextFieldWithLabel";
import {
  CategoryType,
  error_message,
  project_type,
} from "../../constants/constants";
import { LoadState } from "../../constants/enums";
import { useDispatchWrapper, useLoggedInUserInfo } from "../../hooks";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";

import {
  upsertCustomerAsync,
  fetchCustomerAsync,
  clearCustomer,
} from "../../redux/job_customer";
import { IStoreState } from "../../redux/initialStoreState";
import { CustomerBankDetailList } from "./CustomerFormTabs/BankDetails/CustomerBankDetailList";
import { CustomerContactList } from "./CustomerFormTabs/Contact/CustomerContactList";
import { CustomerBranches } from "./CustomerFormTabs/customerBranch/CustomerBranches";
import { CustomerDeliveryAddressList } from "./CustomerFormTabs/DeliveryAddress/CustomerDeliveryAddressList";
import { CustomerDispatchAddressList } from "./CustomerFormTabs/DispachAddress/CustomerDispatchAddressList";
import { CustomerVendorAttachmentList } from "./CustomerFormTabs/attachment/CustomerVendorAttachmentList";

interface IManageCustomer {
  isBillingCompany: number;
}

export const ManageCustomer: React.FC<IManageCustomer> = (props) => {
  const { uuid } = useParams() as { uuid?: string };
  const { isBillingCompany } = props;

  const isUpdate = uuid ? true : false;
  const [loading, setLoading] = React.useState<boolean>(false);

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/customer",
      title: "customer",
    },
    {
      to: isBillingCompany === 0 ? `/customer` : "/billing-company",
      title: isBillingCompany === 0 ? "customer" : "company",
    },
    {
      title: `${isUpdate ? "Edit" : "Create"} ${
        isBillingCompany === 0 ? "Customer" : "Company"
      }`,
    },
  ];

  const {
    data,
    loading: customerLoading,
    error,
  } = useSelector(
    (storeState: IStoreState) => storeState.job_customer.customer,
  );
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const { user_uuid } = useLoggedInUserInfo();

  const { values, errors, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};
      if (!values.customer_name) {
        errors.customer_name = error_message.required;
      }
      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true);
      dispatch(
        upsertCustomerAsync(
          {
            ...values,
            created_by_uuid: user_uuid,
            is_billing_company: isBillingCompany,
          },
          (isSuccess, customer) => {
            setLoading(false);
            if (isSuccess && customer) {
              if (isBillingCompany === 0) {
                navigate(`/customer`);
              } else {
                navigate(`/billing-company/manage/${customer.customer_uuid}`);
              }
            }
          },
        ),
      );
    },
  });

  React.useEffect(() => {
    setValues(data);
  }, [data]);

  React.useEffect(() => {
    if (uuid) {
      dispatch(fetchCustomerAsync(uuid));
    }
  }, [uuid]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCustomer());
    };
  }, []);

  //************Tabs start*************/
  const [selectedTab, setSelectedTab] = React.useState(0);

  const [tabList, setTabList] = React.useState<ITab[]>([]);
  const changeEnquiryTabsList = (isBillingCompany: number) => {
    let hideTabs = isBillingCompany === 1;

    let tabData: ITab[] = [
      {
        label: "Bank Details",
        icon: <NoteAltOutlined />,
      },
      {
        label: "Contact",
        icon: <QuizOutlined />,
      },
      {
        label: isBillingCompany === 0 ? "Billing Addresses" : "Branches",
        icon: <ClearAllOutlined />,
      },
      {
        label:
          isBillingCompany === 0 ? "Delivery Addresses" : "Dispatch Addresses",
        icon: <MapOutlined />,
      },
      {
        label: "Attachments",
        icon: <DocumentScanner />,
        isHidden: hideTabs,
      },
    ];
    setTabList(tabData);
  };

  React.useEffect(() => {
    changeEnquiryTabsList(isBillingCompany);
  }, [isBillingCompany]);

  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: number,
  ) => {
    setSelectedTab(value);
  };

  return (
    <PageLoader
      loading={customerLoading === LoadState.InProgress}
      error={error ? { message: error } : null}
    >
      <PageContainer
        title={
          isUpdate
            ? `Edit ${isBillingCompany === 0 ? "Customer" : "Company"}`
            : `Create ${isBillingCompany === 0 ? "Customer" : "Company"}`
        }
        description="this is inner page"
      >
        <Breadcrumb title="Lead" items={BCrumb} />
        <StandardCard
          // sx={{ ml: 2, mr: 2 }}
          sx={{ my: 2 }}
          heading={
            isUpdate
              ? `Edit ${isBillingCompany === 0 ? "Customer" : "Company"}`
              : `Create ${isBillingCompany === 0 ? "Customer" : "Company"}`
          }
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <TextFieldWithLabel
                  heading={`${
                    isBillingCompany === 0 ? "Customer" : "Company"
                  } Name`}
                  type="text"
                  id="customer_name"
                  value={values.customer_name}
                  fullWidth
                  error={errors.customer_name ? true : false}
                  helperText={errors.customer_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextFieldWithLabel
                  heading={`${
                    isBillingCompany === 0 ? "Customer" : "Company"
                  } Website`}
                  type="text"
                  id="customer_website"
                  fullWidth
                  value={values.customer_website}
                  error={errors.customer_website ? true : false}
                  helperText={errors.customer_website}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextFieldWithLabel
                  heading={"Customer Alias"}
                  type="text"
                  id="legal_entity"
                  fullWidth
                  value={values.legal_entity}
                  error={errors.legal_entity ? true : false}
                  helperText={errors.legal_entity}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Organization Type</CustomFormLabel>
                <ControlledCustomSelect
                  name="organization_type"
                  fullWidth
                  value={values.organization_type}
                  options={project_type}
                  onChange={handleChange}
                  displayEmpty
                  placeholder="Select one"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Registration Type</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  fullWidth
                  name="registration_type"
                  value={values.registration_type}
                  onChange={handleChange}
                  displayEmpty
                  placeholder="Select one"
                  options={CategoryType}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Status</CustomFormLabel>
                <ControlledCustomSelect
                  name="status"
                  fullWidth
                  value={values.status}
                  onChange={handleChange}
                  options={[
                    { label: "Active", value: "ACTIVE" },
                    { label: "Inactive", value: "INACTIVE" },
                  ]}
                  displayEmpty
                  placeholder="Select one"
                />
              </Grid>
            </Grid>
            <Stack direction={"row"} spacing={2} marginTop={4}>
              <Button type="submit" variant="contained">
                Save
              </Button>
            </Stack>
          </form>
        </StandardCard>
      </PageContainer>
      {/********** Tabs start from here *********/}
      {uuid && (
        <>
          <TabsList
            selectedTab={selectedTab}
            tabs={tabList}
            onChange={handleTabChange}
          />
          <StandardCard sx={{ mt: 2 }}>
            <TabPanel value={selectedTab} index={0}>
              <CustomerBankDetailList />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <CustomerContactList />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <CustomerBranches isBillingCompany={isBillingCompany} />
            </TabPanel>
            <TabPanel value={selectedTab} index={3}>
              {isBillingCompany === 0 ? (
                <CustomerDeliveryAddressList />
              ) : (
                <CustomerDispatchAddressList />
              )}
            </TabPanel>
            <TabPanel value={selectedTab} index={4}>
              <CustomerVendorAttachmentList
                isVendor={false}
                entityUUID={values.customer_uuid as string}
                entityName={values.customer_name}
              />
            </TabPanel>
            <LoadingDialog open={loading} />
          </StandardCard>
        </>
      )}
    </PageLoader>
  );
};
