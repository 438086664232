import React from "react";

import { CustomTextField } from "./CustomTextField";
import moment, { Moment } from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { Typography } from "@mui/material";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

export const CustomDatePicker: React.FC<{
  value: string | null;
  placeholder?: string;
  sx?: any;
  disabled?: boolean;
  views?: ("day" | "month" | "year")[];
  inputFormat?: string;
  fullWidth?: boolean;
  errorMessage?: string;
  minDate?: any;
  maxDate?: any;

  onChange: (
    value: any,
    timeWithSeconds: any,
    momentObject: Moment | null,
  ) => void;
}> = (props) => {
  const {
    value,
    sx,
    placeholder,
    views = ["year", "month", "day"],
    inputFormat = "DD-MM-YYYY",
    fullWidth,
    disabled,
    errorMessage,
    minDate,
    maxDate,
  } = props;
  return (
    <>
      <DatePicker
        views={views}
        label={placeholder}
        disabled={disabled}
        format={inputFormat}
        sx={{
          width: fullWidth ? "100%" : "unset",
          "& .MuiInputLabel-root": {
            fontSize: "0.8rem",
            marginTop: "-0.4rem",
          },
          "& .MuiInputLabel-shrink": {
            fontSize: "0.85rem",
            fontWeight: 600, // Increase the font size here
            marginTop: "0.1rem",
          },

          "& .MuiOutlinedInput-input": {
            padding: "9px 13px",
            fontSize: "0.8rem",
            color: "rgb(38, 38, 38)",
          },

          ...sx,
        }}
        minDate={minDate}
        maxDate={maxDate}
        value={value ? moment(value) : null}
        onChange={(newValue) => {
          if (newValue) {
            const newDate = moment(newValue).format("YYYY-MM-DD");
            const newDate1 = moment(newValue).format("YYYY-MM-DD, HH:mm:ss a");
            props.onChange(newDate, newDate1, newValue);
          } else {
            props.onChange(null, null, null);
          }
        }}
      />
      {errorMessage && <ErrorMessage value={errorMessage} />}
    </>
  );
};

export const CustomTimePicker: React.FC<{
  value: any;
  sx?: any;
  fullWidth?: boolean;
  onChange: (value: Moment) => void;
  disabled?: boolean;
}> = (props) => {
  const { value, sx, fullWidth, disabled } = props;
  const timeString = value;
  const momentObject = moment(timeString, "HH:mm:ss");
  return (
    <TimePicker
      value={momentObject}
      disabled={disabled}
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock,
      }}
      format="hh:mm A"
      onChange={(newValue) => {
        if (newValue) {
          props.onChange(newValue);
        }
      }}
      sx={{
        width: fullWidth ? "100%" : "unset",
        "& .MuiOutlinedInput-input": {
          padding: "9px 13px",
          fontSize: "0.8rem",
          color: "rgb(38, 38, 38)",
        },
      }}
    />
  );
};
