import {
  getBillingCompanyFromStorage,
  saveBillingCompanyInStorage,
} from "./BillingCompanyProvider.storage";
import { IBillingCompanyContextState } from "./BillingCompanyProvider.types";

export const checkIfUserHasValidBillingCompany = async (
  comapnyInfo: IBillingCompanyContextState["comapny"][],
  isAdmin: boolean,
): Promise<IBillingCompanyContextState | null> => {
  if (comapnyInfo.length > 0) {
    const currentComapnyInfo = getBillingCompanyFromStorage();
    if (currentComapnyInfo && isAdmin) {
      const hasValidComapny = comapnyInfo.find(
        (company) => company.customer_uuid === currentComapnyInfo.customer_uuid,
      );
      if (hasValidComapny) {
        saveBillingCompanyInStorage(hasValidComapny);
        return { comapny: hasValidComapny };
      }
      saveBillingCompanyInStorage(comapnyInfo[0]);
      return { comapny: comapnyInfo[0] };
    }
    saveBillingCompanyInStorage(comapnyInfo[0]);
    return { comapny: comapnyInfo[0] };
  }
  return null;
};

export const checkIfUserHasValidBillingCompanyV2 = (
  comapnyInfo: IBillingCompanyContextState["comapny"][],
): IBillingCompanyContextState | null => {
  if (comapnyInfo.length > 0) {
    saveBillingCompanyInStorage(comapnyInfo[0]);
    return { comapny: comapnyInfo[0] };
  }
  return null;
};
