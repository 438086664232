import produce from "immer";
import { userDocumentActions } from ".";
import { IStoreState } from "../initialStoreState";
import { defaultUserDocumentState } from "./userDocument.state";
import {
  FETCH_USER_DOCUMENTS_LIST_FAILED,
  FETCH_USER_DOCUMENTS_LIST_PROGRESS,
  FETCH_USER_DOCUMENTS_LIST_SUCCESS,
} from "./userDocumentAction";
import { LoadState } from "../../constants/enums";

export const userDocumentReducer = (
  state: IStoreState["userDocument"] = defaultUserDocumentState,
  action: userDocumentActions,
) => {
  switch (action.type) {
    case FETCH_USER_DOCUMENTS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.NotLoaded;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_USER_DOCUMENTS_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.error = null;
      });
      return newState;
    }

    case FETCH_USER_DOCUMENTS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};
