import { LoadState } from "../../constants/enums";
import { IUserDocument, IUserDocumentState } from "./userDocument.types";

export const defaultUserDocument: IUserDocument = {
  orders_uuid: null,
  type: "",
  file_url: "",
  description: "",
};

export const defaultUserDocumentState: IUserDocumentState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  document: {
    data: defaultUserDocument,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
