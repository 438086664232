import React from "react";
import { BillingCompanyContext } from "./BillingCompanyProvider.context";
import { IJobCustomer } from "../../redux/job_customer";
import { defaultCustomer } from "../../redux/customer";
import { getBillingCompanyFromStorage } from "./BillingCompanyProvider.storage";

export function useBillingCompanyContext() {
  const context = React.useContext(BillingCompanyContext);

  if (!context) {
    throw new Error(
      "BillingCompanyContext: Context must be used inside AuthProvider",
    );
  }

  return context;
}

export const useBillingCompanyFromStorage = (): IJobCustomer => {
  const [billing_info] = React.useState<IJobCustomer>(() => {
    return getBillingCompanyFromStorage();
  });

  return billing_info;
};
