import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IScheduleTask, IScheduleTaskState } from "./scheduleTask.types";
import { uploadFile } from "../../helpers/uploadFile";

export const FETCH_SCHEDULE_TASK_LIST_PROGRESS =
  "FETCH_SCHEDULE_TASK_LIST_PROGRESS";
export const FETCH_SCHEDULE_TASK_LIST_SUCCESS =
  "FETCH_SCHEDULE_TASK_LIST_SUCCESS";
export const FETCH_SCHEDULE_TASK_LIST_FAILED =
  "FETCH_SCHEDULE_TASK_LIST_FAILED";

export const fetchScheduleTaskListProgress = () =>
  action(FETCH_SCHEDULE_TASK_LIST_PROGRESS);
export const fetchScheduleTaskListSuccess = (
  data: IScheduleTaskState["list"],
  totalRecords: number,
) => action(FETCH_SCHEDULE_TASK_LIST_SUCCESS, { data, totalRecords });
export const fetchScheduleTaskListFailed = () =>
  action(FETCH_SCHEDULE_TASK_LIST_FAILED);

export const fetchScheduleTaskListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchScheduleTaskListProgress());
      const searchQuery = getSearchQuery(queryParams);
      const res = await api.get(`/task/get-task-definition${searchQuery}`);
      const data: IScheduleTask[] = res.data.data.TODO || [];
      const totalRecords = res.data.totalRecords;

      dispatch(fetchScheduleTaskListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchScheduleTaskListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertScheduleTaskAsync =
  (
    data: IScheduleTask,
    // file: any | null,
    onCallback: (isSuccess: boolean, ScheduleTask?: IScheduleTask) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, rowId, task_details, ...rest } = data;
      // const asPayload = {
      //   task_definition_uuid: data.task_definition_uuid
      // };

      // const path = await uploadFile(
      //   file,
      //   "POLICY",
      //   data.uploadFile || "",
      //   asPayload
      // );
      const res = await api.post("/task/upsert-task-definition", rest);
      let message = "ScheduleTask saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_SCHEDULE_TASK_PROGRESS = "FETCH_SCHEDULE_TASK_PROGRESS";
export const FETCH_SCHEDULE_TASK_SUCCESS = "FETCH_SCHEDULE_TASK_SUCCESS";
export const FETCH_SCHEDULE_TASK_FAILED = "FETCH_SCHEDULE_TASK_FAILED";

export const fetchScheduleTaskProgress = () =>
  action(FETCH_SCHEDULE_TASK_PROGRESS);
export const fetchScheduleTaskSuccess = (data: IScheduleTask) =>
  action(FETCH_SCHEDULE_TASK_SUCCESS, { data });
export const fetchScheduleTaskFailed = (errorMessage: string) =>
  action(FETCH_SCHEDULE_TASK_FAILED, { errorMessage });

export const fetchScheduleTaskAsync =
  (id: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchScheduleTaskProgress());
      const res = await api.get(`/quotes/get-products?product_uuid=${id}`);
      const data: IScheduleTask[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchScheduleTaskSuccess(data[0]));
      } else {
        dispatch(
          fetchScheduleTaskFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchScheduleTaskFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_SCHEDULE_TASK = "CLEAR_SCHEDULE_TASK";
export const clearScheduleTask = () => action(CLEAR_SCHEDULE_TASK);
export const CLEAR_SCHEDULE_TASK_STATE = "CLEAR_ PROJECT_STATE";
export const clearScheduleTaskState = () => action(CLEAR_SCHEDULE_TASK_STATE);
