import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IVendor } from "./vendor.typs";

export const FETCH_VENDOR_LIST_PROGRESS = "FETCH_VENDOR_LIST_PROGRESS";
export const FETCH_VENDOR_LIST_SUCCESS = "FETCH_VENDOR_LIST_SUCCESS";
export const FETCH_VENDOR_LIST_FAILED = "FETCH_VENDOR_LIST_FAILED";

export const fetchVendorListProgress = () => action(FETCH_VENDOR_LIST_PROGRESS);
export const fetchVendorListSuccess = (data: IVendor[], totalRecords: number) =>
  action(FETCH_VENDOR_LIST_SUCCESS, { data, totalRecords });
export const fetchVendorListFailed = () => action(FETCH_VENDOR_LIST_FAILED);

export const fetchVendorListAsync =
  (
    queryParams: IQueryParams,
    billing_company_uuid: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchVendorListProgress());
      let finalUrl = `/vendor/get-vendor${searchQuery}`;
      if (billing_company_uuid) {
        finalUrl += `&billing_company_uuid=${billing_company_uuid}`;
      }
      const res = await api.get(finalUrl);
      const data: IVendor[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchVendorListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchVendorListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertVendorAsync =
  (
    vendor: IVendor,
    onCallback: (isSuccess: boolean, vendor?: IVendor) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const { create_ts, insert_ts, ...rest } = vendor;
      const res = await api.post("/vendor/upsert-vendor", rest);
      const data = res.data.data;
      onCallback(true, data);
      dispatch(
        showMessage({
          type: "success",
          message: "Vendor saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_VENDOR_PROGRESS = "FETCH_VENDOR_PROGRESS";
export const FETCH_VENDOR_SUCCESS = "FETCH_VENDOR_SUCCESS";
export const FETCH_VENDOR_FAILED = "FETCH_VENDOR_FAILED";

export const fetchVendorProgress = () => action(FETCH_VENDOR_PROGRESS);
export const fetchVendorSuccess = (data: IVendor) =>
  action(FETCH_VENDOR_SUCCESS, { data });
export const fetchVendorFailed = (errorMessage: string) =>
  action(FETCH_VENDOR_FAILED, { errorMessage });

export const fetchVendorAsync =
  (
    vendorUUID: string,
    billing_company_uuid: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchVendorProgress());
      let url = `/vendor/get-vendor?vendor_uuid=${vendorUUID}`;
      if (billing_company_uuid) {
        url += `&billing_company_uuid=${billing_company_uuid}`;
      }
      const res = await api.get(url);
      const data: IVendor[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchVendorSuccess(data[0]));
      } else {
        dispatch(fetchVendorFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchVendorFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_VENDOR = "CLEAR_VENDOR";
export const CLEAR_VENDOR_STATE = "CLEAR_VENDOR_STATE";

export const clearVendor = () => action(CLEAR_VENDOR);
export const clearVendorState = () => action(CLEAR_VENDOR_STATE);
