import { ThunkAction } from "redux-thunk";
import { api } from "../../api/api";
import { getSearchQuery } from "../common/helpers";
import {
  IExpense,
  IExpenseCatApproval,
  IGroupedApprovalExpense,
  IGroupedCatApprovalExpense,
  IHandleDynamicApproval,
} from "./expenseModule.types";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { IQueryParams } from "../common/common.types";
import { action } from "typesafe-actions";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";

const execGroupDataByCreatedByName = (
  data: IExpense[],
): IGroupedApprovalExpense[] => {
  const groupedData = Object.values(
    data.reduce((acc, obj) => {
      //@ts-ignore
      if (!acc[obj.created_by_name]) {
        //@ts-ignore
        acc[obj.created_by_name] = {
          id: obj.created_by_name,
          ...obj,
          childs: [obj],
        };
      } else {
        //@ts-ignore
        acc[obj.created_by_name].childs.push(obj);
      }
      return acc;
    }, {}),
  );
  return groupedData as IGroupedApprovalExpense[];
};

const execGroupDataByCreatedByProjectName = (
  data: IExpense[],
): IGroupedApprovalExpense[] => {
  const groupedData = Object.values(
    data.reduce((acc, obj) => {
      //@ts-ignore
      if (!acc[obj.project_name]) {
        //@ts-ignore
        acc[obj.project_name] = {
          id: obj.project_name,
          ...obj,
          childs: [obj],
        };
      } else {
        //@ts-ignore
        acc[obj.project_name].childs.push(obj);
      }
      return acc;
    }, {}),
  );
  return groupedData as IGroupedApprovalExpense[];
};

export const FETCH_CAT_APPROVAL_EXPENSE_LIST_PROGRESS =
  "FETCH_CAT_APPROVAL_EXPENSE_LIST_PROGRESS";
export const FETCH_CAT_APPROVAL_EXPENSE_LIST_SUCCESS =
  "FETCH_CAT_APPROVAL_EXPENSE_LIST_SUCCESS";
export const FETCH_CAT_APPROVAL_EXPENSE_LIST_FAILED =
  "FETCH_CAT_APPROVAL_EXPENSE_LIST_FAILED";

export const fetchCatApprovalExpenseListProgress = () =>
  action(FETCH_CAT_APPROVAL_EXPENSE_LIST_PROGRESS);
export const fetchCatApprovalExpenseListSuccess = (
  data: IGroupedApprovalExpense[],
  totalRecords: number,
) =>
  action(FETCH_CAT_APPROVAL_EXPENSE_LIST_SUCCESS, {
    data,
    totalRecords,
  });
export const fetchCatApprovalExpenseListFailed = () =>
  action(FETCH_CAT_APPROVAL_EXPENSE_LIST_FAILED);

export const fetchCatApprovalExpenseListAsync =
  (
    queryParams: IQueryParams,
    expenseTypeValue: string,
    reportUuid?: string,
    unreported?: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      let searchQuery = getSearchQuery(queryParams);
      if (reportUuid) {
        searchQuery += `&report_uuid=${reportUuid}`;
      }

      if (unreported) {
        searchQuery += `&unreported=${unreported}`;
      }

      dispatch(fetchCatApprovalExpenseListProgress());
      let finalUrl = `/dynamicApproval/get-approval${searchQuery}&table_name=latest_expense`;
      const res = await api.get(finalUrl);
      const data: IExpense[] = res.data.data;
      const filteredData = !expenseTypeValue
        ? data.filter((item) => item.expense_type === "EXPENSE")
        : data.filter((item) => item.expense_type === expenseTypeValue);

      const finalData =
        expenseTypeValue === "EXPENSE"
          ? execGroupDataByCreatedByName(filteredData)
          : execGroupDataByCreatedByProjectName(filteredData);

      dispatch(
        fetchCatApprovalExpenseListSuccess(finalData, res.data.totalRecords),
      );
    } catch (err: any) {
      dispatch(fetchCatApprovalExpenseListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ********************** Handle Approval ********************** //
export const handleDynamicApprovalAsync =
  (
    data: IHandleDynamicApproval,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/dynamicApproval/handle-approval", data);
      let message = "Action success!";
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(true);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
