import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { uploadFile } from "../../helpers/uploadFile";
import { ITaskCategory, ITaskCategoryState } from "./taskCategory.types";

export const FETCH_TASK_CATEGORY_LIST_PROGRESS =
  "FETCH_TASK_CATEGORY_LIST_PROGRESS";
export const FETCH_TASK_CATEGORY_LIST_SUCCESS =
  "FETCH_TASK_CATEGORY_LIST_SUCCESS";
export const FETCH_TASK_CATEGORY_LIST_FAILED =
  "FETCH_TASK_CATEGORY_LIST_FAILED";

export const fetchTaskCategoryListProgress = () =>
  action(FETCH_TASK_CATEGORY_LIST_PROGRESS);
export const fetchTaskCategoryListSuccess = (
  data: ITaskCategoryState["list"],
  totalRecords: number,
) => action(FETCH_TASK_CATEGORY_LIST_SUCCESS, { data, totalRecords });
export const fetchTaskCategoryListFailed = () =>
  action(FETCH_TASK_CATEGORY_LIST_FAILED);

export const fetchTaskCategoryListAsync =
  (
    queryParams: IQueryParams,
    billing_company_uuid: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskCategoryListProgress());
      const searchQuery = getSearchQuery(queryParams);
      let url = `/category/get-category${searchQuery}`;
      if (billing_company_uuid) {
        url += `&billing_company_uuid=${billing_company_uuid}`;
      }
      const res = await api.get(url);
      const data: ITaskCategory[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchTaskCategoryListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchTaskCategoryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertTaskCategoryAsync =
  (
    data: ITaskCategory,
    // file: any | null,
    onCallback: (isSuccess: boolean, TaskCategory?: ITaskCategory) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, rowId, ...rest } = data;
      const res = await api.post("/category/upsert-category", rest);
      let message = "Category saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//************ Single Category **************

export const FETCH_TASK_CATEGORY_PROGRESS = "FETCH_TASK_CATEGORY_PROGRESS";
export const FETCH_TASK_CATEGORY_SUCCESS = "FETCH_TASK_CATEGORY_SUCCESS";
export const FETCH_TASK_CATEGORY_FAILED = "FETCH_TASK_CATEGORY_FAILED";

export const fetchTaskCategoryProgress = () =>
  action(FETCH_TASK_CATEGORY_PROGRESS);
export const fetchTaskCategorySuccess = (data: ITaskCategory) =>
  action(FETCH_TASK_CATEGORY_SUCCESS, { data });
export const fetchTaskCategoryFailed = (errorMessage: string) =>
  action(FETCH_TASK_CATEGORY_FAILED, { errorMessage });

export const fetchTaskCategoryAsync =
  (
    categoryUUID: string | null,
    billing_company_uuid: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(fetchTaskCategoryProgress());
      let url = `/category/get-category?category_uuid=${categoryUUID}`;
      if (billing_company_uuid) {
        url += `&billing_company_uuid=${billing_company_uuid}`;
      }
      const res = await api.get(url);
      const data: ITaskCategory = res.data.data[0];
      dispatch(fetchTaskCategorySuccess(data));
    } catch (err: any) {
      dispatch(fetchTaskCategoryFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_TASK_CATEGORY = "CLEAR_TASK_CATEGORY";
export const clearTaskCategory = () => action(CLEAR_TASK_CATEGORY);
export const CLEAR_TASK_CATEGORY_LIST_STATE = "CLEAR_TASK_CATEGORY_LIST_STATE";
export const clearTaskCategoryState = () =>
  action(CLEAR_TASK_CATEGORY_LIST_STATE);
