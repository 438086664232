import React from "react";
import { IDataTableV2ColumnsVisibilityProps } from "./DataTableV2ColumnsVisibility.types";
import {
  Box,
  Button,
  Divider,
  Paper,
  Popover,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { CustomCheckBoxWithLabel } from "../../../formsComponents";
import { ViewWeek } from "@mui/icons-material";
import { datatableV2FormatText } from "../../helpers/datatableV2FormatText";
import { IDataTableV2MasterColumn } from "../../interfaces/IDataTableV2Props";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  DragDropContext,
  Droppable,
  Draggable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import produce from "immer";

export const DataTableV2ColumnsVisibility: React.FC<
  IDataTableV2ColumnsVisibilityProps
> = (props) => {
  const { columnVisibility, onChange = () => {} } = props;
  const initialColumnVisibility = React.useRef(columnVisibility);
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const canBeOpen = open && Boolean(anchorEl);
  const [columns, setColumns] = React.useState<any>([]);

  const id = canBeOpen ? "data-table-v2-search-transition-popper" : undefined;

  const masterColumns = React.useMemo(() => {
    const data = [...props.masterColumns];
    const finalList: IDataTableV2MasterColumn[] = Array(data.length);
    if (columnVisibility) {
      const columnsVisibilityData = columnVisibility;
      for (const key in columnsVisibilityData) {
        const columnData = columnsVisibilityData[key];
        const fieldIndex = data.findIndex((x) => x.fieldName === key);
        if (fieldIndex > -1 && columnData.order !== undefined) {
          finalList[columnData.order] = data[fieldIndex];
          data.splice(fieldIndex, 1);
        }
      }
      for (const column of data) {
        for (let i = 0; i < finalList.length; i++) {
          const newColumn = finalList[i];
          if (!newColumn) {
            finalList[i] = column;
            break;
          }
        }
      }
    }
    return finalList;
  }, [props.masterColumns, columnVisibility]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleCheckBox =
    (field: IDataTableV2MasterColumn, isChecked: boolean) =>
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const data = { ...columnVisibility };
      if (!data[field.fieldName]) {
        const index = masterColumns.findIndex((x) => x.key === field.key);
        data[field.fieldName] = {
          visible: !isChecked,
          order: index,
        };
      } else {
        data[field.fieldName] = {
          ...data[field.fieldName],
          visible: !isChecked,
        };
      }

      onChange(data);
    };
  const handleDragEnd = (result: any) => {
    const { source, destination } = result;

    // Ensure the destination is valid
    if (!destination) {
      return;
    }

    const { index: sourceIndex } = source;
    const { index: destinationIndex } = destination;

    // Get the dragged field
    const draggedField = masterColumns[sourceIndex];

    const newData = produce(columnVisibility, (draftData) => {
      if (sourceIndex < destinationIndex) {
        // Dragging down: Shift items between sourceIndex and destinationIndex up
        Object.values(draftData).forEach((item) => {
          if (item.order > sourceIndex && item.order <= destinationIndex) {
            item.order -= 1; // Shift up
          }
        });
      } else if (sourceIndex > destinationIndex) {
        // Dragging up: Shift items between destinationIndex and sourceIndex down
        Object.values(draftData).forEach((item) => {
          if (item.order >= destinationIndex && item.order < sourceIndex) {
            item.order += 1; // Shift down
          }
        });
      }

      // Update the dragged item's order to destinationIndex
      draftData[draggedField.fieldName] = {
        ...draftData[draggedField.fieldName],
        order: destinationIndex,
      };
      if (draftData[draggedField.fieldName].visible === undefined) {
        draftData[draggedField.fieldName].visible = true;
      }
    });

    onChange(newData);
  };

  const handleReset = () => {
    onChange(initialColumnVisibility.current);
  };

  return (
    <>
      <Button
        variant="text"
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          padding: "0px 0px",
          [theme.breakpoints.up("md")]: {
            padding: "2px 2px",
          },
          [theme.breakpoints.up("lg")]: {
            padding: "2px 4px",
          },
          [theme.breakpoints.up("xl")]: {
            padding: "6px 8px",
          },
        }}
      >
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <ViewWeek
            color="primary"
            sx={{
              fontSize: 16,
              [theme.breakpoints.up("md")]: {
                fontSize: 18,
              },
              [theme.breakpoints.up("lg")]: {
                fontSize: 23,
              },
              [theme.breakpoints.up("xl")]: {
                fontSize: 25,
              },
            }}
          />
          <Typography
            variant="body1"
            fontSize={"1.2rem"}
            fontWeight={600}
            color="primary.main"
          >
            Columns
          </Typography>
        </Stack>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        sx={{ zIndex: 1200 }}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <>
          <Paper
            variant="outlined"
            sx={{
              minWidth: {
                xl: 300,
              },
            }}
          >
            <Box
              sx={{
                overflow: "auto",
                maxHeight: {
                  md: 160,
                  lg: 260,
                  xl: 400,
                },
                p: {
                  md: 0.5,
                  lg: 1,
                  xl: 1,
                },
                pl: {
                  md: 1.2,
                  lg: 1.2,
                  xl: 1.2,
                },
              }}
            >
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="columns">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {masterColumns.map((data, index) => {
                        let isChecked = false;
                        if (data.fieldName in columnVisibility) {
                          if (columnVisibility[data.fieldName].visible) {
                            isChecked = true;
                          } else {
                            isChecked = false;
                          }
                        } else if (!data.isHidden) {
                          isChecked = true;
                        }
                        return (
                          <Draggable
                            key={data.key}
                            draggableId={data.key}
                            index={index}
                          >
                            {(provided) => (
                              <Stack
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{
                                  ...provided.draggableProps.style,
                                }}
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <CustomCheckBoxWithLabel
                                  label={datatableV2FormatText(data.headerName)}
                                  checked={isChecked}
                                  onChange={handleCheckBox(data, isChecked)}
                                />
                                <Box {...provided.dragHandleProps}>
                                  <DragIndicatorIcon />
                                </Box>
                              </Stack>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
            <Divider />
            <Stack
              direction={"row"}
              justifyContent={"flex-end"}
              p={0.5}
              pr={1.2}
            >
              <Button
                variant="text"
                size="large"
                sx={{ fontWeight: 600, fontSize: "1rem" }}
                onClick={handleReset}
              >
                RESET
              </Button>
            </Stack>
          </Paper>
        </>
      </Popover>
    </>
  );
};
