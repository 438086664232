/* eslint-disable react-hooks/exhaustive-deps */
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { debounce } from "lodash";
import React from "react";
import { api } from "../../api/api";
import { CustomFormLabel, CustomTextField } from "../formsComponents";
import { IUser, IUsersAutoSearch } from "./AutoCompleteSearches.types";
import { useBillingCompanyContext } from "../../providers";
const INITIAL_STATE: IUser = {
  first_name: "",
  personal_email: "",
  user_uuid: "",
  last_name: "",
  job_title: null,
  manager_uuid: null,
  user_type: null,
  assigned_phone_number: null,
  shared_email: null,
  mobile: null,
  home_phone: null,
  linkedin_profile: null,
  hire_date: null,
  last_day_at_work: null,
  branch: null,
  department: null,
  fax: null,
  date_of_birth: null,
  mother_maiden_name: null,
  photo: null,
  signature: null,
  street_address: null,
  unit_or_suite: null,
  city: null,
  province_or_state: null,
  postal_code: null,
  country: null,
  languages_known: null,
  documents: null,
  status: "ACTIVE",
};

export const UsersAutoSearch: React.FC<IUsersAutoSearch> = (props) => {
  const {
    label,
    column,
    value,
    onSelect,
    disabled,
    error,
    roleGroup,
    roleValue,
    hideAddButton,
    placeholder = "",
  } = props;
  const [options, setOptions] = React.useState<IUser[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearchText] = React.useState<any>("");
  const { comapny } = useBillingCompanyContext();
  const [openUserDialog, setOpenUserDialog] = React.useState(false);
  const userRoleGroup = roleGroup ? roleGroup : null;
  const fetchSuggestion = async (
    enteredValue: string,
    roleGroup: string | null,
    billing_company_uuid: string,
    roleValue?: string,
  ) => {
    // setLoading(true);
    try {
      let searchQuery = "";
      if (billing_company_uuid) {
        searchQuery += `billing_company_uuid=${billing_company_uuid}&`;
      }

      if (enteredValue?.length > 0) {
        searchQuery = `columns=first_name,last_name&value=${enteredValue}&`;
      }

      if (roleGroup) {
        searchQuery += `role_group=${roleGroup}&`;
      }
      if (roleValue) {
        searchQuery += `role_value=${roleValue}&`;
      }

      const res = await api.get(
        `/user/get-user?${searchQuery}status=ACTIVE&pageNo=1&itemPerPage=20`,
      );
      const data: IUser[] = res.data.data;
      const finalData: IUser[] = [];
      for (const user of data) {
        finalData.push(user);
      }
      if (value?.user_uuid?.length > 0) {
        const option: IUser = {
          ...INITIAL_STATE,

          user_uuid: value?.user_uuid,
          first_name: value?.first_name,
        };
        finalData.push(option);
      }
      setOptions(finalData);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // setLoading(false);
    } finally {
      // setLoading(false);
    }
  };
  const debounceFn = React.useCallback(debounce(fetchSuggestion, 800), []);

  const getOptionLabel = (option: IUser | string) => {
    if (typeof option === "string") return option || "";
    return `${option.first_name} ${option?.last_name || ""}`;
  };

  const getValue = () => {
    return (
      options.find((option) => option.user_uuid === value?.user_uuid) || null
    );
  };

  const handleToggle = () => {
    setOpenUserDialog(!openUserDialog);
  };

  const handleDropDownOnFocus = () => {
    if (search.length === 0 && options.length === 0) {
      debounceFn("", userRoleGroup, comapny.customer_uuid || "", roleValue);
    }
  };

  React.useEffect(() => {
    if (search && search !== value?.first_name) {
      debounceFn(search, userRoleGroup, comapny.customer_uuid || "", roleValue);
    }
  }, [search]);

  // React.useEffect(() => {
  // 	debounceFn(search, userRoleGroup, comapny.customer_uuid || "", roleValue);
  // }, [roleValue]);

  React.useEffect(() => {
    if (value && typeof value === "object" && value?.user_uuid?.length > 0) {
      const option: IUser = {
        ...INITIAL_STATE,
        user_uuid: value?.user_uuid,
        first_name: value?.first_name,
      };
      setOptions([option]);
    }
  }, [value]);
  return (
    <>
      {label && (
        <Box display="flex" justifyContent={"flex-start"} alignItems="center">
          <CustomFormLabel display={"block"}>{` ${label}  `}</CustomFormLabel>
          {!hideAddButton &&
            search.length > 0 &&
            options.length === 0 &&
            !loading && (
              <Box
                sx={{
                  paddingLeft: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginTop: 1,
                }}
                onClick={handleToggle}
              >
                <AddCircleIcon fontSize="small" color="primary" />
                <Typography
                  variant="h6"
                  color="primary"
                  marginLeft={0.5}
                  marginTop={0.3}
                  fontWeight={500}
                >
                  Add User
                </Typography>
              </Box>
            )}
        </Box>
      )}
      <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
        <Autocomplete
          id="google-map-demo"
          fullWidth
          disabled={disabled || loading}
          sx={{
            ".MuiOutlinedInput-root": {
              paddingTop: "2px",
              paddingBottom: "2px",
              fontSize: "0.8rem",
              color: "rgb(38, 38, 38)",
              width: "100%",
              backgroundColor: disabled ? "#f8f9fb" : "inherit",
            },
          }}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={(option, value) =>
            typeof option === "string"
              ? option === value //@ts-ignore
              : option.first_name === value?.first_name
          }
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          value={getValue()}
          loading={loading}
          noOptionsText="No User"
          onFocus={handleDropDownOnFocus}
          //@ts-ignore
          onChange={(
            event: React.ChangeEvent<HTMLElement>,
            newValue: IUser | null,
          ) => {
            if (newValue) {
              onSelect(newValue);
            } else {
              onSelect(INITIAL_STATE);
            }
          }}
          onInputChange={(event, newInputValue) => {
            if ((event && event.type === "change") || !newInputValue) {
              setSearchText(newInputValue);
            }
          }}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              fullWidth
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              disabled={disabled}
              error={error ? true : false}
              helperText={error}
            />
          )}
        />
        {/* <Box>
          <Tooltip title="Create Customer">
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              onClick={handleToggle}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Box> */}
      </Stack>
      {/* {openUserDialog && (
        <ManageUser
          open={openUserDialog}
          company={INITIAL_STATE}
          onClose={handleToggle}
          onComplete={handleComplete}
        />
      )}  */}
    </>
  );
};
