import React, { useState } from "react";
import { Grid, Box, Stack, Button } from "@mui/material";
import { CustomFormLabel, CustomTextField } from "../formsComponents";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../LocationAutoComplete/LocationAutoComplete";
import { Dialog } from "../Dialogs/Dialog";
interface AddressFieldWithDialogProps {
  address: string | null;
  onAddressChange: (data: ILocationResponsePayload) => void;
}

export const AddressSearchAutoComplete: React.FC<
  AddressFieldWithDialogProps
> = ({ address, onAddressChange }) => {
  const [addressDialogOpen, setAddressDialogOpen] = useState(false);

  // Open and close dialog handlers
  const handleOpenDialog = () => setAddressDialogOpen(true);
  const handleCloseDialog = () => setAddressDialogOpen(false);

  // Handle address selection from LocationAutoComplete component
  const handleAddressSelect = (data: ILocationResponsePayload) => {
    onAddressChange(data);
    setAddressDialogOpen(false);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Box>
          {/* Address label and button */}
          <Stack direction="row" justifyContent="space-between">
            <CustomFormLabel>Address</CustomFormLabel>
            <Button variant="text" onClick={handleOpenDialog}>
              Search Address
            </Button>
          </Stack>

          {/* Address input field */}
          <CustomTextField
            name="address"
            variant="outlined"
            size="small"
            fullWidth
            value={address}
            disabled
          />

          {/* Dialog for address search */}
        </Box>
      </Grid>
      <Dialog
        open={addressDialogOpen}
        title="Search Address"
        onClose={handleCloseDialog}
        size="xs"
        actions={[
          {
            type: "button",
            label: "Close",
            variant: "text",
            onClick: handleCloseDialog,
          },
        ]}
      >
        <Grid item xs={12} md={12} marginBottom={30}>
          <CustomFormLabel>Enter Address</CustomFormLabel>
          <LocationAutoComplete
            id="address-autocomplete"
            variant="outlined"
            size="small"
            fullWidth
            value={address}
            onLocationChange={handleAddressSelect}
          />
        </Grid>
      </Dialog>
    </Grid>
  );
};
