import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { uploadFile } from "../../helpers/uploadFile";
import { ITaskListItem, ITaskListState } from "./taskList.types";
import { omit } from "lodash";
import { IProject } from "../projects/project.types";

export const FETCH_TASK_LIST_PROGRESS = "FETCH_TASK_LIST_PROGRESS";
export const FETCH_TASK_LIST_SUCCESS = "FETCH_TASK_LIST_SUCCESS";
export const FETCH_TASK_LIST_FAILED = "FETCH_TASK_LIST_FAILED";

export const fetchTaskListProgress = () => action(FETCH_TASK_LIST_PROGRESS);
export const fetchTaskListSuccess = (
  data: ITaskListState["list"],
  totalRecords: number,
) => action(FETCH_TASK_LIST_SUCCESS, { data, totalRecords });
export const fetchTaskListFailed = () => action(FETCH_TASK_LIST_FAILED);

export const fetchTaskListAsync =
  (
    queryParams: IQueryParams,
    billing_company_uuid: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskListProgress());

      const searchQuery = getSearchQuery(queryParams);

      let url = `/task/get-task-list${searchQuery}`;
      if (billing_company_uuid) {
        url += `&billing_company_uuid=${billing_company_uuid}`;
      }
      const res = await api.get(url);
      const data: ITaskListItem[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchTaskListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchTaskListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertTaskListItemAsync =
  (
    data: ITaskListItem,
    // file: any | null,
    onCallback: (isSuccess: boolean, TaskListItem?: ITaskListItem) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const keysToOmit =
        data.status === "COMPLETED"
          ? ["create_ts", "insert_ts", "rowId"]
          : ["create_ts", "insert_ts", "rowId", "time_taken"];

      const rest = omit(data, keysToOmit);
      const res = await api.post("/task/upsert-task", rest);
      let message = "Task saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertTaskWithAiAsync =
  (
    title: string,
    project_uuid: string,
    onCallback: (isSuccess: boolean, TaskListItem?: IProject) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/task/assign-task-with-ai?project_uuid=${project_uuid}&prompt=${title}`,
      );
      let message = "Task saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_TASK_LIST_ITEM = "CLEAR_TASK_LIST_ITEM";
export const clearTaskListItem = () => action(CLEAR_TASK_LIST_ITEM);
export const CLEAR_TASK_LIST_STATE = "CLEAR_TASK_LIST_STATE";
export const clearTaskListState = () => action(CLEAR_TASK_LIST_STATE);
