import { defaultCustomer } from "../../redux/customer";
import { IJobCustomer } from "../../redux/job_customer";

export const LOCAL_KEY_BILLING_INFO = "billing_info";

export const getBillingCompanyFromStorage = (): IJobCustomer => {
  const OutletInfo = localStorage.getItem(LOCAL_KEY_BILLING_INFO);
  return OutletInfo
    ? JSON.parse(OutletInfo)
    : (defaultCustomer as IJobCustomer);
};

export const saveBillingCompanyInStorage = (customer_info: IJobCustomer) => {
  localStorage.setItem(LOCAL_KEY_BILLING_INFO, JSON.stringify(customer_info));
};
