import React from "react";
import { IBillingCompanyContextState } from "./BillingCompanyProvider.types";
import { defaultBillingCompanyContextState } from "./BillingCompanyProvider.state";
import { useAuth } from "../../contexts/AuthProvider/AuthProvider";
import { BillingCompanyContext } from "./BillingCompanyProvider.context";
import { fetchBillingCompanyInfoAsync } from "./BillingCompanyProvider.services";
import { LinearProgress } from "@mui/material";
import { NoBillingComapnyScreen } from "./views/NoBillingComapnyScreen";

export const BillingCompanyProvider: React.FC<{ children: React.ReactNode }> = (
  props,
) => {
  const [outletInfo, setOutletInfo] =
    React.useState<IBillingCompanyContextState>(
      defaultBillingCompanyContextState,
    );
  const [loading, setLoading] = React.useState(false);
  const [zeroOutletDialog, setZeroOutletDialog] =
    React.useState<boolean>(false);

  const { userInfo } = useAuth();
  const {
    user: { user_uuid, role_value, billing_company_uuid },
  } = userInfo;
  const isAdmin = role_value === "ADMIN" || role_value === "SUPERADMIN";

  React.useEffect(() => {
    if (!user_uuid) return;
    getOutDetails();
  }, [user_uuid, billing_company_uuid]);

  const getOutDetails = async () => {
    setLoading(true);
    const validCompany = await fetchBillingCompanyInfoAsync(
      billing_company_uuid,
      isAdmin,
    );
    if (validCompany) {
      setOutletInfo(validCompany);
    } else {
      setZeroOutletDialog(true);
    }
    setLoading(false);
  };

  if (loading) return <LinearProgress />;

  if (!isAdmin && ((user_uuid && !billing_company_uuid) || zeroOutletDialog))
    return <NoBillingComapnyScreen />;
  return (
    <BillingCompanyContext.Provider value={outletInfo}>
      {props.children}
    </BillingCompanyContext.Provider>
  );
};
