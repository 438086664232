import { api } from "../../api/api";
import { IJobCustomer } from "../../redux/job_customer";
import {
  checkIfUserHasValidBillingCompany,
  checkIfUserHasValidBillingCompanyV2,
} from "./BillingCompanyProvider.helpers";
import { saveBillingCompanyInStorage } from "./BillingCompanyProvider.storage";
import { IBillingCompanyContextState } from "./BillingCompanyProvider.types";

export const fetchBillingCompanyInfoAsync = (
  billing_company_uuid: string,
  isAdmin: boolean,
) =>
  new Promise<IBillingCompanyContextState | null>((resolve, reject) => {
    console.log("fetchBillingCompanyInfoAsync", billing_company_uuid);
    // const isAdmin = billing_company_uuid ? false : true;
    let api_url = `/customer/get-customer`;
    if (billing_company_uuid && !isAdmin) {
      api_url += `?customer_uuid=${billing_company_uuid}`;
    }
    api.get(api_url).then((response) => {
      const comapnyInfo = response.data.data as IJobCustomer[];
      resolve(checkIfUserHasValidBillingCompany(comapnyInfo, isAdmin));
    });
  });
