import { Button, Grid, Stack } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  AddressAutoComplete,
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../components/LocationAutoComplete/LocationAutoComplete";
import { PhoneTextField } from "../../../../components/PhoneTextField/PhoneTextField";
import { RightPanel } from "../../../../components/RightPanel";
import { TextFieldWithLabel } from "../../../../components/TextFieldWithLabel/TextFieldWithLabel";
import { CustomFormLabel } from "../../../../components/formsComponents";
import { error_message } from "../../../../constants/constants";
import { useDispatchWrapper } from "../../../../hooks";

import {
  IJobCustomerBranch,
  upsertCustomerBranchAsync,
} from "../../../../redux/customerBranch";
import { AddressAutoCompleteByPincode } from "../../../../components/AutoCompleteSearches/AddressAutoSearchByPincode";

import { useNavigate } from "react-router-dom";
import { AddressSearchAutoComplete } from "../../../../components/AddressAutoComplete/AddressSearchAutoComplete";

interface IManageCustomerBranch {
  open: boolean;
  data: IJobCustomerBranch;
  isBillingCompany: boolean;
  onClose: () => void;
  onComplete: (data: IJobCustomerBranch) => void;
}
export const ManageCustomerBranch: React.FC<IManageCustomerBranch> = (
  props,
) => {
  const { open, data, isBillingCompany, onClose, onComplete } = props;
  const isUpdate = data?.customer_branch_uuid ? true : false;
  const navigate = useNavigate();

  const dispatch = useDispatchWrapper();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};
      if (!values.customer_branch_name) {
        errors.customer_branch_name = error_message.required;
      }
      if (!values.customer_branch_gst_in) {
        errors.customer_branch_gst_in = error_message.required;
      }
      return errors;
    },
    onSubmit: async (values) => {
      dispatch(
        upsertCustomerBranchAsync(
          {
            ...values,
            customer_name: values.customer_name,
            customer_uuid: values.customer_uuid,
          },
          (isSuccess, customerBranch) => {
            if (isSuccess && customerBranch) {
              onComplete(customerBranch);
            }
          },
        ),
      );
    },
  });

  const handleLocation = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      customer_branch_address_line2: data.address,
      customer_branch_address_city: data.city,
      customer_branch_address_state: data.state,
      customer_branch_address_country: data.country,
      customer_branch_address_pincode: data.postalCode,
    });
  };

  return (
    <RightPanel
      open={open}
      heading={isUpdate ? "Update Branch" : "Create Branch"}
      subHeading={`Customer: ${values.customer_name}`}
      width="50%"
      isWrappedWithForm
      onFormSubmit={handleSubmit}
      actionButtons={() => (
        <Stack direction={"row"} spacing={2}>
          <Button variant="contained" type="button" onClick={onClose}>
            Close
          </Button>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </Stack>
      )}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} py={2}>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Branch Name"}
              type="text"
              id="customer_branch_name"
              fullWidth
              value={values.customer_branch_name}
              error={errors.customer_branch_name ? true : false}
              helperText={errors.customer_branch_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"GST In"}
              type="text"
              id="customer_branch_gst_in"
              fullWidth
              value={values.customer_branch_gst_in}
              error={errors.customer_branch_gst_in ? true : false}
              helperText={errors.customer_branch_gst_in}
              onChange={handleChange}
            />
          </Grid>
          {/* <Grid item xs={12} lg={3}>
                        <CustomerAutoSearch
                            label="Customer Name"
                            value={{
                                customer_uuid: values.customer_uuid,
                                customer_name: values.customer_name,
                            }}
                            onSelect={(value) => {
                                setValues({
                                    ...values,
                                    customer_uuid: value.customer_uuid as string,
                                    customer_name: value.customer_name,
                                });
                            }}
                            error={errors.customer_uuid}
                            disabled
                        />
                    </Grid> */}
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Mobile Number</CustomFormLabel>
            <PhoneTextField
              name="customer_branch_mobile"
              variant="outlined"
              size="small"
              fullWidth
              value={values.customer_branch_mobile}
              error={errors.customer_branch_mobile ? true : false}
              helperText={errors.customer_branch_mobile}
              setFieldValue={setFieldValue}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Phone Number</CustomFormLabel>
            <PhoneTextField
              name="customer_branch_phone_number"
              variant="outlined"
              size="small"
              fullWidth
              value={values.customer_branch_phone_number}
              error={errors.customer_branch_phone_number ? true : false}
              helperText={errors.customer_branch_phone_number}
              setFieldValue={setFieldValue}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Email"}
              type="text"
              id="customer_branch_mail_id"
              fullWidth
              value={values.customer_branch_mail_id}
              error={errors.customer_branch_mail_id ? true : false}
              helperText={errors.customer_branch_mail_id}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Website"}
              type="text"
              id="customer_branch_website"
              fullWidth
              value={values.customer_branch_website}
              error={errors.customer_branch_website ? true : false}
              helperText={errors.customer_branch_website}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CustomFormLabel>Branch Location</CustomFormLabel>
            <AddressSearchAutoComplete
              address={values.customer_branch_address_line2}
              onAddressChange={handleLocation}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Address line 1"}
              type="text"
              id="customer_branch_address_line1"
              fullWidth
              value={values.customer_branch_address_line1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Address line 2"}
              type="text"
              id="customer_branch_address_line2"
              fullWidth
              value={values.customer_branch_address_line2}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"City"}
              type="text"
              id="customer_branch_address_city"
              fullWidth
              value={values.customer_branch_address_city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"State"}
              type="text"
              id="customer_branch_address_state"
              fullWidth
              value={values.customer_branch_address_state}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <AddressAutoCompleteByPincode
              label="Pincode"
              value={values.customer_branch_address_pincode}
              onSelect={(data) => {
                setValues({
                  ...values,
                  customer_branch_address_pincode: data.pincode as string,
                  customer_branch_address_line2: data.office_name as string,
                  customer_branch_address_city: data.district_name as string,
                  customer_branch_address_state: data.state_name as string,
                  customer_branch_address_country: "India",
                });
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Country"}
              type="text"
              id="customer_branch_address_country"
              fullWidth
              value={values.customer_branch_address_country}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading="PAN No"
              type="text"
              id="pan_no"
              fullWidth
              value={values.pan_no}
              onChange={handleChange}
            />
          </Grid>
          {isBillingCompany && (
            <Grid item xs={12} lg={3}>
              <TextFieldWithLabel
                heading={"Invoice No Sequence"}
                type="text"
                id="invoice_no_sequence"
                fullWidth
                value={values.invoice_no_sequence}
                onChange={handleChange}
              />
            </Grid>
          )}
        </Grid>
      </form>
    </RightPanel>
  );
};
