import { LoadState } from "../../constants/enums";
import {
  IApprovalHistory,
  IExcelPreview,
  IExpense,
  IExpenseApproval,
  IExpenseCatApproval,
  IExpenseCategory,
  IExpenseModuleState,
} from "./expenseModule.types";

export const defaultExpense: IExpense = {
  expense_type: "EXPENSE",
  expense_uuid: null,
  report_name: null,
  report_uuid: null,
  project_name: "",
  project_uuid: "",
  user_uuid: null,
  user_name: null,

  created_by_name: "",
  project_manager_uuid: "",
  project_manager_name: "",
  finance_manager_uuid: "",
  finance_manager_name: "",
  job_order_no: null,
  job_uuid: null,
  job_name: null,
  department_uuid: "",
  department_name: "",
  expense_date: "",
  receipt: null,
  merchant: null,
  vendor_uuid: null,
  expense_category_uuid: null,
  expense_category_name: null,
  category_manager_name: "",
  category_manager_uuid: "",
  amount: null,
  description: null,
  business_purpose: null,
  advance_amount: null,
  requested_advance_amount: null,
  reimbursed_amount: null,
  eligible_reimbursement_amount: null,
  status: "EXPENSE_REQUESTED",
  insert_ts: undefined,
  create_ts: undefined,
  rowId: undefined,
  requested_by_uuid: "",
  is_user_approver: false,
  is_deduct_from_advance: false,
  approval_uuid: null,
  billing_company_uuid: null,
  billing_company_name: null,
  billing_company_branch_uuid: null,
  billing_company_branch_name: null,
  billing_company_branch_address_state: null,
  additional_fields: [],
};

export const defaultExpenseCategory: IExpenseCategory = {
  expense_category_uuid: null,
  expense_category_name: "",
  expense_category_description: null,
  status: "ACTIVE",
  category_manager_name: "",
  category_manager_uuid: "",
  billing_company_uuid: null,
  billing_company_name: null,
  billing_company_branch_uuid: null,
  billing_company_branch_name: null,
  billing_company_branch_address_state: null,
};

export const defaultExpenseApproval: IExpenseApproval = {
  expense_type: "EXPENSE",
  report_uuid: null,
  report_name: null,
  project_uuid: "",
  project_name: "",
  project_manager_name: "",
  project_manager_uuid: "",
  finance_manager_name: "",
  finance_manager_uuid: "",
  department_uuid: "",
  department_name: "",
  expense_uuid_list: [],
  business_purpose: null,
  category: null,
  advance_amount: null,
  reimbursed_amount: null,
  description: null,
  approval_uuid: null,
  status: "REPORT_REQUESTED",
};

export const defaultExpenseModuleState: IExpenseModuleState = {
  expenseList: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  expenseData: {
    data: defaultExpense,
    loading: LoadState.NotLoaded,
    error: null,
  },
  expenseCategoryList: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },

  expenseCategoryData: {
    data: defaultExpenseCategory,
    loading: LoadState.NotLoaded,
    error: null,
  },

  expenseApprovalList: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  expenseApprovalData: {
    data: defaultExpenseApproval,
    loading: LoadState.NotLoaded,
    error: null,
  },
  expenseCatApproval: {
    list: [],
    loading: LoadState.NotLoaded,
    totalRecords: 0,
  },
  expenseCatApprovalList: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  ApprovalHistoryList: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
};

export const defaultCatApproval: IExpenseCatApproval = {
  dynamic_approval_count_uuid: null,
  table_name: "latest_expense",
  dynamic_uuid: "",
  dynamic_table_name: "latest_expense_category",
  link_table: "",
  link_column: "",
  approval_hierarchy: [
    [
      {
        type: "",
        uuid: "",
      },
    ],
  ],
  approval_raise_status: "",
  previous_status: "",
  next_status: "",
  status: "ACTIVE",
};

const defaultSelfBankData = {
  expense_type: null,
  account_number: null,
  service_outlet: null,
  currency_code_of_account_number: null,
  part_tran_type: null,
  transaction_amount: null,
  transaction_particular: null,
  reference_number: null,
  rate_code: null,
  account_report_code: null,
  reference_amount: null,
  instrument_type: null,
  reference_currency_code: null,
  value_date: null,
  particulars_1: null,
  create_ts: undefined,
  insert_ts: undefined,
};

const defaultOtherBankData = {
  expense_type: null,
  amount: null,
  debit_account: null,
  ifsc: null,
  beneficiary_account: null,
  beneficiary_name: null,
  address: null,
  tran_particular: null,
  create_ts: undefined,
  insert_ts: undefined,
};

export const defaultExcelPreview = {
  selfBankData: [defaultSelfBankData],
  otherBankData: [defaultOtherBankData],
};

export const initialApprovalHistory: IApprovalHistory = {
  dynamic_approval_uuid: "",
  current_level: 0,
  table_name: "",
  dynamic_uuid: "",
  record_uuid: "",
  record_id: null,
  record_column_name: "",
  approval_uuids: [],
  requested_by_uuid: "",
  previous_status: "",
  status: "",
  next_status: "",
  remark: null,
  created_by_uuid: "",
  create_ts: new Date().toISOString(), // Keeping it as a string
};
