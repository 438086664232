import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../redux/initialStoreState";
import {
  fetchApprovalExpenseListAsync,
  fetchCatApprovalExpenseListAsync,
  handleDynamicApprovalAsync,
  IExpense,
  IGroupedApprovalExpense,
} from "../../../redux/expenseModule";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import { useDispatchWrapper } from "../../../hooks";
import { ITableCommandBarProps } from "../../../components/Table/components/TableComandBar/interfaces/ITableCommandBarProps";
import { CustomSelect } from "../../../components/formsComponents";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  IconButton,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import moment from "moment";
import {
  CSVExportPlugin,
  RefreshPlugin,
  SearchPlugin,
} from "../../../components/Table/plugins";
import { LoadState, MODULE_IDS } from "../../../constants/enums";
import { TableCommandBar } from "../../../components/Table/components/TableComandBar/TableCommandBar";
import { TableSkeleton } from "../../../components/TableSkeleton";
import { useNavigate } from "react-router-dom";
import { TablePaginationActions } from "../../../components/Table/TablePaginationActions";
import { CustomLink } from "../../../components/CustomLink/CustomLink";

import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { ButtonWithRemark } from "../../approval/components/ButtonWithRemark";
import { StatusRenderer } from "../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { IBreadcrumbProps } from "../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { PageContainer } from "../../../components/container/PageContainer";
import { Breadcrumb } from "../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { StandardCard } from "../../../components/Cards";
import { displayPriceFormat } from "../../../helpers/isNotNull";
import { TabsList } from "../../../components/Tabs/TabsList";
import { TabPanel } from "../../../components/Tabs/TabPanel";
import { ApprovalHistoryList } from "../components/ApprovalHistory";

export const CategoryApprovalExpenses: React.FC = () => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "Expense",
    },
  ];
  const { list, loading, totalRecords } = useSelector(
    (storeState: IStoreState) =>
      storeState.expenseModule.expenseCatApprovalList,
  );

  const [status, setStatus] = React.useState<IExpense["status"] | "-1">("-1");
  const [searchColumns, setSearchColumns] = React.useState<string[]>([
    "project_name",
  ]);
  const [search, setSearch] = React.useState<string>("");
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [pagination, setPagination] = React.useState({
    pageNumber: 1,
    rowsPerPage: 10,
  });
  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "last28Days" });
  const [expenseTypeValue, setExpenseTypeValue] = React.useState("EXPENSE");

  const dispatch = useDispatchWrapper();
  const [tab, setTab] = React.useState(0);

  console.log(expenseTypeValue, "expenseTypeValue");

  // handle click toggle button
  const handleChangeToggleButton = (
    event: React.MouseEvent<HTMLElement>,
    value: string,
  ) => {
    setExpenseTypeValue(value);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const tableCommandBarProps: ITableCommandBarProps = {
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e: any) =>
                  setStatus(e.target.value as IExpense["status"])
                }
              >
                <MenuItem value="-1">All Status</MenuItem>
              </CustomSelect>
            ),
          },
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    const newDate = moment(newValue).format("YYYY-MM-DD");
                    setDate({ ...date, fromDate: newDate });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    const newDate = moment(newValue).format("YYYY-MM-DD");
                    setDate({ ...date, toDate: newDate });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "expense_type",
            onRender: (columns, items) => (
              <ToggleButtonGroup
                color="primary"
                value={expenseTypeValue}
                exclusive
                onChange={handleChangeToggleButton}
                aria-label="expense-type"
                size="small"
              >
                <ToggleButton value="EXPENSE">Expense</ToggleButton>
                <ToggleButton value="JOB">Job</ToggleButton>
                <ToggleButton value="ADVANCE">Advance</ToggleButton>
              </ToggleButtonGroup>
            ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <CSVExportPlugin
                columns={columns}
                items={items}
                filePrefixName="Report"
              />
            ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {},
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchColumns}
                  dropdownOptions={[
                    { label: "Project Name", value: "project_name" },
                    { label: "Created By", value: "created_by_name" },
                    { label: "Category", value: "expense_category_name" },

                    { label: "Expense Type", value: "expense_type" },
                  ]}
                  onDropdownChange={(value) => setSearchColumns(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    columns: [],
    items: [],
  };

  const fetchList = () => {
    dispatch(
      fetchCatApprovalExpenseListAsync(
        {
          status: status,
          page: pagination.pageNumber,
          rowsPerPage: pagination.rowsPerPage,
          columns: searchColumns,
          value: search,
          fromDate: date.fromDate,
          toDate: date.toDate,
        },
        expenseTypeValue,
      ),
    );
  };

  React.useEffect(() => {
    fetchList();
  }, [tablePagination, date, status, tab, search, date, expenseTypeValue]);

  const handlePageChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setTablePagination({ ...tablePagination, pageNumber: newPage + 1 });
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10);

    setTablePagination({
      ...tablePagination,
      rowsInPerPage:
        rowsPerPage === -1 ? totalRecords || list.length : rowsPerPage,
    });
  };

  return (
    <PageContainer title="View Expense" description="this is inner page">
      <Breadcrumb title="View Expense" items={BCrumb} />
      <TabsList
        sx={{ mb: 2, mr: 2 }}
        varaint="fullWidth"
        selectedTab={tab}
        tabs={[
          { label: " Approval Expense List" },
          { label: "Approval Expense History" },
        ]}
        onChange={handleTabChange}
      />
      <TabPanel value={tab} index={0}>
        <StandardCard heading="Approval Expense List">
          <TableCommandBar {...tableCommandBarProps} />
          <Card
            className="tableScroll"
            sx={{
              borderRadius: "5px",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 3px",
              border: 0,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              padding: 0,
              marginTop: 0,
            }}
          >
            <CardContent sx={{ padding: "0px !important" }}>
              <Box
                sx={{
                  overflowY: "auto",
                  overflowX: "auto",
                }}
              >
                <Table
                  aria-label="collapsible table"
                  sx={{
                    whiteSpace: {
                      xs: "nowrap",
                      sm: "unset",
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>
                        {expenseTypeValue === "EXPENSE" ? (
                          <Typography variant="h6" fontWeight={"600"}>
                            User
                          </Typography>
                        ) : (
                          <Typography variant="h6" fontWeight={"600"}>
                            Project Name
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" fontWeight={"600"}>
                          Total Count
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" fontWeight={"600"}>
                          {expenseTypeValue === "ADVANCE"
                            ? "Advance Amount"
                            : "Reimbursed Amount"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" fontWeight={"600"}>
                          Date
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading === LoadState.InProgress ? (
                      <TableSkeleton numberOfCells={4} numberOfRows={5} />
                    ) : (
                      list.map((data) => (
                        <Row
                          key={data.approval_uuid}
                          data={data}
                          expenseTypeValue={expenseTypeValue}
                        />
                      ))
                    )}
                  </TableBody>
                </Table>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                      sx={{ borderBottom: 0 }}
                      //colSpan={columns.length + 1}
                      count={totalRecords}
                      rowsPerPage={tablePagination.rowsInPerPage}
                      page={tablePagination.pageNumber - 1}
                      SelectProps={{
                        native: true,
                      }}
                      labelDisplayedRows={(data) => {
                        return (
                          <>
                            {data.from}-{data.to}
                          </>
                        );
                      }}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleRowsPerPageChange}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Box>
            </CardContent>
          </Card>
        </StandardCard>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <ApprovalHistoryList />
      </TabPanel>
    </PageContainer>
  );
};

function Row(props: {
  data: IGroupedApprovalExpense;
  expenseTypeValue: string;
}) {
  const { data, expenseTypeValue } = props;
  const navigate = useNavigate();
  const dispatch = useDispatchWrapper();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const renderLink = (row: IExpense, value: string | number) => {
    return (
      <CustomLink
        to={`/expense/manage/${row.expense_uuid}`}
        label={value.toString()}
      />
    );
  };

  const handleApprovalAction = (
    approvalType: string,
    remark: string,
    values: IExpense,
  ) => {
    if (approvalType === "rollback") {
      dispatch(
        handleDynamicApprovalAsync(
          {
            dynamic_approval_uuid: values.dynamic_approval_uuid || "",
            remark: remark,
            status: "ROLLBACK",
          },
          (isSuccess) => {
            if (isSuccess) {
              navigate("/approval/expenses");
            }
          },
        ),
      );
    } else if (approvalType === "approve") {
      dispatch(
        handleDynamicApprovalAsync(
          {
            dynamic_approval_uuid: values.dynamic_approval_uuid || "",
            remark: remark,
            status: "APPROVED",
          },
          (isSuccess) => {
            if (isSuccess) {
              setLoading(true);
              if (expenseTypeValue === "ADVANCE") {
                navigate("/advcance-expenses");
              } else {
                navigate("/expense");
              }
              setLoading(false);
            }
          },
        ),
      );
    } else if (approvalType === "reject") {
      dispatch(
        handleDynamicApprovalAsync(
          {
            dynamic_approval_uuid: values.dynamic_approval_uuid || "",
            remark: remark,
            status: "REJECTED",
          },
          (isSuccess) => {
            if (isSuccess) {
              if (expenseTypeValue === "ADVANCE") {
                navigate("/advcance-expenses");
              } else {
                navigate("/expense");
              }
            }
          },
        ),
      );
    }
  };

  const totals = data.childs.reduce(
    (acc, item) => {
      return {
        advanceAmount:
          acc.advanceAmount + Number(item.requested_advance_amount || 0),
        reimbursedAmount:
          acc.reimbursedAmount + Number(item.reimbursed_amount || 0),
      };
    },
    { advanceAmount: 0, reimbursedAmount: 0 },
  );

  return (
    <>
      <TableRow
        sx={
          open
            ? {
                background: (theme) =>
                  `${
                    theme.palette.mode === "dark"
                      ? theme.palette.grey.A200
                      : theme.palette.error.light
                  }`,
              }
            : null
        }
      >
        <TableCell
          sx={{
            borderBottom: "0",
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell
          sx={{
            borderBottom: "0",
          }}
        >
          {expenseTypeValue === "EXPENSE" ? (
            <Typography
              color="primary"
              variant="h6"
              onClick={() => setOpen(!open)}
              sx={{ cursor: "pointer" }}
            >
              {data.user_name}
            </Typography>
          ) : (
            <Typography
              color="primary"
              variant="h6"
              onClick={() => setOpen(!open)}
              sx={{ cursor: "pointer" }}
            >
              {data.project_name}
            </Typography>
          )}
        </TableCell>
        <TableCell
          sx={{
            borderBottom: "0",
          }}
        >
          <Typography variant="body1"> {data.childs.length}</Typography>
        </TableCell>
        <TableCell
          sx={{
            borderBottom: "0",
          }}
        >
          <Typography variant="body1">
            {displayPriceFormat(totals.advanceAmount + totals.reimbursedAmount)}
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            borderBottom: "0",
          }}
        >
          <Typography variant="body1" fontWeight={600}>
            {moment(data.create_ts).format("DD-MM-YYYY")}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{ paddingBottom: 0, paddingTop: 0, pl: 0, pr: 0 }}
          colSpan={13}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{
              background: (theme) =>
                `${
                  theme.palette.mode === "dark"
                    ? theme.palette.grey.A200
                    : theme.palette.grey.A700
                }`,
            }}
          >
            <Box marginBottom={5} marginTop={3}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Actions
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Project Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Expense Type
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Created By
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        {expenseTypeValue === "ADVANCE"
                          ? "Advance Amount"
                          : "Reimbursed Amount"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Category
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Expense Date
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Status
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.childs.map((item) => {
                    return (
                      <TableRow key={item.business_purpose}>
                        <TableCell>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="start"
                            spacing={1}
                          >
                            <StandardTableActions
                              onEditClick={() => {
                                navigate(
                                  expenseTypeValue === "EXPENSE"
                                    ? `/expense/manage/${item.expense_uuid}`
                                    : `/advcance-expenses/manage/${item.expense_uuid}`,
                                );
                              }}
                            />
                            {item.is_user_approver === false ? (
                              <ButtonWithRemark
                                disabled={false}
                                confirmButtonText="Rollback"
                                onConfirm={(remark) =>
                                  handleApprovalAction("rollback", remark, item)
                                }
                              />
                            ) : (
                              <>
                                <ButtonWithRemark
                                  disabled={false}
                                  confirmButtonText="Approve"
                                  onConfirm={(remark) =>
                                    handleApprovalAction(
                                      "approve",
                                      remark,
                                      item,
                                    )
                                  }
                                />
                                <ButtonWithRemark
                                  disabled={false}
                                  confirmButtonText="Reject"
                                  onConfirm={(remark) =>
                                    handleApprovalAction("reject", remark, item)
                                  }
                                />
                              </>
                            )}
                          </Stack>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          {renderLink(item, item.project_name || "--")}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          <StatusRenderer status={item.expense_type} />
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          <StatusRenderer status={item.created_by_name} />
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          <Typography variant="body1">
                            {expenseTypeValue === "ADVANCE"
                              ? displayPriceFormat(
                                  item.requested_advance_amount,
                                )
                              : displayPriceFormat(item.reimbursed_amount)}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          <Typography variant="body1">
                            {item.expense_category_name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          <Typography variant="body1">
                            {moment(item.expense_date).format("DD-MM-YYYY")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "0",
                          }}
                        >
                          <StatusRenderer status={item.status} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
