import { api } from "../../../api/api";
import { IFileUpload } from "../FileUpload.type";

export const uploadMultipleFile = async (
  files: IFileUpload[],
  module_name: string,
  asPayload: {
    [key: string]: string | number | null;
  },
) => {
  let filesCount = 0;
  const formdata = new FormData();

  console.log("Appending files to FormData");
  console.log("files in uploadMultipleFile", files);
  // Append files to FormData
  for (let file of files) {
    if (file.file) {
      filesCount++;
      formdata.append("files", file.file);
    }
  }

  console.log("Appending module_name and as_payload to FormData");
  // Append module_name and as_payload to FormData
  formdata.append("module_name", module_name);
  formdata.append("as_payload", JSON.stringify(asPayload));

  // If no files are provided, return the original files with their paths
  if (filesCount === 0) {
    console.log("No files provided, returning original files with paths");
    return { files, paths: files.map((item) => item.path) };
  }

  try {
    console.log("Uploading files to the server");
    // Upload files to the server
    const res = await api.post("/general/upload-files", formdata);
    const data: string[] = res.data.data;

    // If the server returns paths, update the files with the new paths
    if (data && data.length > 0) {
      console.log("Server returned paths, updating files with new paths");
      let dataIndex = 0;
      const finalList = files.map((item) => {
        if (item.file && dataIndex < data.length) {
          const updatedItem = {
            ...item,
            file: null,
            path: data[dataIndex] || item.path,
          };
          dataIndex++;
          return updatedItem;
        }
        return item;
      });
      console.log(
        "Returning files with paths:",
        finalList.map((item) => item.path),
      );
      return {
        files: finalList,
        paths: finalList.map((item) => item.path),
      };
    }

    // If no paths are returned, return the original files with their paths
    console.log("No paths returned, returning original files with paths");
    console.log(
      "Returning files with paths:",
      files.map((item) => item.path),
    );
    return {
      files,
      paths: files.map((item) => item.path),
    };
  } catch (error) {
    console.error("Error uploading files:", error);
    // If an error occurs, return the original files with their paths
    return {
      files,
      paths: files.map((item) => item.path),
    };
  }
};
