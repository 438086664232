import { Avatar, Box, Button, MenuItem, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { StatusRenderer } from "../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";

import { CustomOnClickWrapper } from "../../../../components/CustomLink/CustomClickWrapper";
import { ConfirmDialog } from "../../../../components/Dialogs/ConfirmDialog";
import LoadingDialog from "../../../../components/Dialogs/LoadingDialog";
import { StandardTableActions } from "../../../../components/StandardTableActions/StandardTableActions";
import { DataTable } from "../../../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../components/Table/interfaces/IDataTableProps";
import {
  CSVExportPlugin,
  RefreshPlugin,
  SearchPlugin,
} from "../../../../components/Table/plugins";
import { PageContainer } from "../../../../components/container/PageContainer";
import { CustomSelect } from "../../../../components/formsComponents";
import { LoadState, MODULE_IDS } from "../../../../constants/enums";
import { useDispatchWrapper } from "../../../../hooks";
import {
  clearCustomerVendorAttachmentState,
  defaultCustomerVendorAttachment,
  fetchCustomerVendorAttachmentListAsync,
  ICustomerVendorAttachment,
  upsertCustomerVendorAttachmentAsync,
} from "../../../../redux/customerVendorAttachment";
import { IStoreState } from "../../../../redux/initialStoreState";
import { ManageCustomerVendorAttachment } from "./ManageCustomerVendorAttachment";
import { downLoadFile } from "../../../../components/FileUpload/utils";
import { RoleBasedCustomButton } from "../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";

interface InputProps {
  isVendor: boolean;
  entityUUID: string;
  entityName: string;
}

export const CustomerVendorAttachmentList: React.FC<InputProps> = ({
  isVendor,
  entityUUID,
  entityName,
}) => {
  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] =
    React.useState<ICustomerVendorAttachment | null>(null);

  const {
    data: list,
    totalRecords,
    loading,
  } = useSelector(
    (storeState: IStoreState) => storeState.customerVendorAttachment.list,
  );

  const [status, setStatus] = React.useState<
    ICustomerVendorAttachment["status"] | "-1"
  >("-1");
  const [searchColumns, setSearchColumns] = React.useState<string[]>([]);
  const [search, setSearch] = React.useState<string>("");
  const [pagination, setPagination] = React.useState<{
    pageNumber: number;
    rowsPerPage: number;
  }>({ pageNumber: 1, rowsPerPage: 10 });

  // ******Modal Start******
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dialogData, setDialogData] = React.useState<ICustomerVendorAttachment>(
    defaultCustomerVendorAttachment,
  );

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setDialogData({
      ...defaultCustomerVendorAttachment,
      customer_name: entityName,
      customer_uuid: entityUUID || "",
      is_vendor: isVendor ? "YES" : "NO",
    });
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleComplete = () => {
    setOpenDialog(false);
    fetchList();
  };

  // ******Modal End******

  const handleDeleteAttachment = (data: ICustomerVendorAttachment) => {
    setShowDeleteDialog(null);
    setSaveLoading(true);
    dispatch(
      upsertCustomerVendorAttachmentAsync(
        {
          ...data,
          status: "INACTIVE",
        },
        null,
        (isSuccess) => {
          if (isSuccess) {
            fetchList();
          }
          setSaveLoading(false);
        },
      ),
    );
  };
  const handleDownloadAttachment = async (data: ICustomerVendorAttachment) => {
    setSaveLoading(true);
    await downLoadFile(data.link);
    setSaveLoading(false);
  };

  const dataTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: pagination.rowsPerPage,
    isDataLoading: loading !== LoadState.Loaded,
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e: any) => setStatus(e.target.value as "ACTIVE")}
              >
                <MenuItem value="-1">All Status</MenuItem>
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="INACTIVE">In Active</MenuItem>
              </CustomSelect>
            ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <CSVExportPlugin
                columns={columns}
                items={items}
                filePrefixName="Report"
              />
            ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {},
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchColumns}
                  dropdownOptions={[
                    {
                      label: "Attachment Name",
                      value: "customer_attachment_name",
                    },
                  ]}
                  onDropdownChange={(value) => setSearchColumns(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICustomerVendorAttachment) => {
          return (
            <StandardTableActions
              // commentBoxTypeId={row.task_definition_uuid}
              onEditClick={() => {
                setOpenDialog(true);
                setDialogData(row);
              }}
              onDeleteClick={() => {
                setShowDeleteDialog(row);
              }}
              onDownLoadClick={() => {
                handleDownloadAttachment(row);
              }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 30,
        renderType: RenderType.CHIP_WARNING,
        onRowCellRender: (value, row: ICustomerVendorAttachment) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        exportCellWidth: 40,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICustomerVendorAttachment) => {
          return (
            <CustomOnClickWrapper
              label={row.customer_name || ""}
              onClick={() => {
                setOpenDialog(true);
                setDialogData(row);
              }}
            />
          );
        },
      },
      {
        key: "customer_attachment_name",
        headerName: "Attachement Name",
        fieldName: "customer_attachment_name",
        exportCellWidth: 40,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "insert_ts",
        headerName: "Created at",
        fieldName: "insert_ts",
        exportCellWidth: 40,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICustomerVendorAttachment) => {
          return (
            <Typography variant="body1">
              {" "}
              {row.create_ts?.split("T")[0]}
            </Typography>
          );
        },
      },
    ],
    items: list,
    onPageChange: (newPageNumber: number) => {
      setPagination({ ...pagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setPagination({
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
      });
    },
  };
  const fetchList = () => {
    dispatch(
      fetchCustomerVendorAttachmentListAsync(
        {
          status: status,
          page: pagination.pageNumber,
          rowsPerPage: pagination.rowsPerPage,
          columns: searchColumns,
          value: search,
        },
        entityUUID,
      ),
    );
  };

  // React.useEffect(() => {
  //     if (customerUUID) {
  //         dispatch(fetchCustomerAsync(customerUUID))
  //     }
  // }, [customerUUID]);

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, status, search]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCustomerVendorAttachmentState());
    };
  }, []);
  return (
    <PageContainer title="" description="">
      <Box
        display={"flex"}
        justifyContent={"end"}
        sx={{
          mx: 5,
          my: 2,
        }}
      >
        <RoleBasedCustomButton
          moduleId={MODULE_IDS.COMPANY_ATTACHMENT}
          variant="contained"
          onClick={() => handleOpenDialog()}
        >
          Add New
        </RoleBasedCustomButton>
      </Box>
      <DataTable {...dataTableProps} />
      {openDialog && (
        <ManageCustomerVendorAttachment
          open={openDialog}
          data={dialogData}
          onClose={() => handleCloseDialog()}
          onComplete={() => handleComplete()}
        />
      )}
      {showDeleteDialog && (
        <ConfirmDialog
          content="Are you sure you want to delete this attachment?"
          open={!!showDeleteDialog}
          onClose={() => setShowDeleteDialog(null)}
          onConfrim={() => handleDeleteAttachment(showDeleteDialog)}
        />
      )}
      <LoadingDialog open={saveLoading} />
    </PageContainer>
  );
};
