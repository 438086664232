export enum LoadState {
  NotLoaded = 0,
  InProgress = 1,
  Loaded = 2,
  Failed = 3,
}

export enum SaveState {
  NotStarted = 0,
  Progress = 1,
  Saved = 2,
}

export enum USER_ROLES {
  ADMIN = 1,
  CSR = 2,
  PRODUCER = 3,
  MARKETER = 4,
  BRANCH_MANAGER = 5,
}

export enum TASK_SUBTYPE {
  VEHICLE_CHANGE = "Vehicle Change",
  DRIVER_CHANGE = "Driver Change",
  COVERAGE_CHANGE = "Coverage Change",
  GARAGE_PLATE_CHANGE = "Garage Plate Change",
  INTERESTED_PARTY_CHANGE = "Interested Party Change",
  LESSOR_CHANGE = "Lessor Change",
  ADDRESS_CHANGE = "Address Change",
  RISK_LOCATION_CHANGE = "Risk Location Change",
  CANCELLATION_REINSTALLMENT = "Cancellation/Reinstatement",
  BUSINESS_SUBMISSION = "Business Submission",
  GENERAL_CHANGE = "General Change",
  PAYMENT_AUTH_CHANGE = "Payment Authorization Change",
  LAO_BOR = "LAO/BOR",
  NEW_BUSINESS = "Business",
  NEW_PERSONAL_AUTO = "Personal Auto",
  NEW_HOME = "Home",
  NEW_COMMERCIAL_AUTO = "Commercial Auto",
  REMARKET_COMMERCIAL_AUTO = "Commercial Auto",
  REMARKET_HOME = "Home",
  REMARKET_BUSINESS = "Business",
  REMARKET_PERSONAL_AUTO = "Personal Auto",
}

export enum POLICIES {
  BUSINESS_POLICY = "Business Policy",
  AUTOMOBILE_POLICY = "Automobile Policy",
  HABITATIONAL_POLICY = "Habitational Policy",
  COMMERCIAL_AUTO_POLICY = "Commercial Auto Policy",
  LIFE_AND_FINCANCIAL_POLICY = "Life & Fincancial Policy",
}

export enum POLICIES_NAMES {
  BUSINESS_POLICY = "Business",
  AUTOMOBILE_POLICY = "Automobile",
  HABITATIONAL_POLICY = "Habitational",
  COMMERCIAL_AUTO_POLICY = "Commercial Auto",
  LIFE_AND_FINCANCIAL_POLICY = "Life & Fincancial",
}

export enum ERROR_MESSAGES {
  "NO_RECORD_FOUND" = "Unfortunately, there are no records available at the moment.",
  "SERVER_ERROR" = "Someting went to be wrong!",
  "POLICY_NOT_FOUND" = "Oops! We couldn't find any records at the moment. Please ensure that the provided information is accurate and try again.",
}

export enum MODULE_IDS {
  PROJECT = "PROJECTS_PROJECT_LATEST_PROJECT_WITH_TEAM",

  TASK_LIST = "TASKS_TASK LIST_LATEST_TASKS",
  TASKBOARD = "TASKS_TASKBOARD_LATEST_TASKS",
  SCHEDULE_TASK = "TASKS_SCHEDULE TASK_LATEST_TASK_DEFINITION",
  TASK_CATEGORY = "TASKS_TASK CATEGORY_LATEST_CATEGORY",
  TASK_CALENDER = "TASKS_TASK CALENDER_LATEST_TASKS",

  USERS = "ADMIN_USERS_LATEST_USER",
  MANAGE_SITE = "ADMIN_MANAGE SITE_LATEST_MANAGE_SITE",
  SECURITY = "ADMIN_SECURITY_ROLE_MODULE",
  ROLES = "latest_roles",
  USER_BRANCH = "DATA MANAGEMENT_BRANCH_LATEST_BRANCH",

  DEPARTMENT = "DEPARTMENT_DEPARTMENT_LATEST_DEPARTMENT",
  EXPENSE = "EXPENSE_EXPENSE_LATEST_EXPENSE",
  EXPENSE_CATEGORY = "EXPENSE_EXPENSE CATEGORY_LATEST_EXPENSE_CATEGORY",
  ADMIN_EXPENSE_CATEGORY = "ADMIN_EXPENSE CATEGORY APPROVAL_LATEST_EXPENSE_CATEGORY",

  COMPANY = "COMPANY_COMPANY_LATEST_CUSTOMER",

  COMPANY_BRANCH = "COMPANY_COMPANY BRANCH_LATEST_CUSTOMER_BRANCH",
  COMPANY_DELIVERY_ADDRESS = "COMPANY_COMPANY DELIVERY ADDRESS_LATEST_CUSTOMER_DELIVERY_ADDRESS",
  COMPANY_DISPATCH_ADDRESS = "COMPANY_COMPANY DISPATCH ADDRESS_LATEST_CUSTOMER_DISPATCH_ADDRESS",
  COMPANY_CONTACTS = "COMPANY_COMPANY CONTACTS_LATEST_CONTACTS",
  COMPANY_BANK_DETAILS = "COMPANY_COMPANY BANK DETAILS_LATEST_BANK_DETAILS",
  COMPANY_ATTACHMENT = "COMPANY_COMPANY ATTACHMENT_LATEST_CUSTOMER_ATTACHMENT",

  VENDORS = "JOB BOARD_VENDORS_LATEST_VENDORS",

  PURCHASE_ORDER = "JOB BOARD_PURCHASE ORDER_LATEST_PURCHASE_ORDER",
  APPROVAL_PURCHASE_ORDER = "APPROVAL_PURCHASE ORDER_LATEST_PURCHASE_ORDER",
  SALE_ORDER = "JOB BOARD_SALE ORDER_LATEST_SALE_ORDER",
  QUOTATION = "latest_quotation",
  JOBS = "JOB BOARD_JOB_LATEST_JOB",
  APPROVAL_JOBS = "APPROVAL_JOB_LATEST_JOB",
  CATEGORY_APPROVAL = "DYNAMIC APPROVAL_CATEGORY_LATEST_EXPENSE",
  CONTACTS = "",
}

export const app_table_names = {
  PROJECT: "latest_project_with_team",
  TASKBOARD: "latest_tasks",
  TASK_LIST: "latest_tasks",
  SCHEDULE_TASK: "latest_task_definition",
  TASK_CATEGORY: "latest_category",
  TASK_CALENDER: "latest_tasks",
  USERS: "latest_user",
  ROLES: "latest_roles",
  USER_BRANCH: "latest_branch",
  EXPENSE_CATEGORY: "latest_expense_category",
  DEPARTMENT: "latest_Department",
  EXPENSE: "latest_expense",
  EXPENSE_REPORT: "latest_expense_report",
  SECURITY: "role_module",
  MANAGE_SITE: "latest_manage_site",
  COMPANY: "latest_COMPANY",
  CONTACT: "latest_contacts",
  BILLING_COMPANY: "latest_billing_company",
  PURCHASE_ORDER: "latest_purchase_order",
  VENDOR: "latest_vendors",
  SALE_ORDER: "latest_sale_order",
  QUOTATION: "latest_quotation",
  JOBS: "latest_job",
  CATEGORY_APPROVAL: "latest_expense",
};

export enum MODULE_NAMES {
  EXPENSE = "Expense",
}
