import { LoadState } from "../../constants/enums";
import { IJobs, IJobsState } from "./jobs_types";

export const initialJobs: IJobs = {
  job_uuid: null,
  job_order_no: "",
  job_order_date: "",
  job_name: "",
  project_uuid: "",
  project_name: "",
  project_manager_uuid: "",
  project_manager_name: "",
  finance_manager_uuid: "",
  finance_manager_name: "",
  vendor_uuid: null,
  vendor_name: null,
  attachment: [],
  status: "DRAFT",
  billing_company_uuid: null,
  billing_company_name: null,
  billing_company_branch_uuid: null,
  billing_company_branch_name: null,
  billing_company_branch_address_state: null,
  requested_by_uuid: "",
  additional_fields: [],
};
export const initialJobsState: IJobsState = {
  jobsList: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  jobsData: {
    data: initialJobs,
    loading: LoadState.NotLoaded,
    error: null,
  },
  jobs_approval_ist: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
