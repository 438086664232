import { LoadState } from "../../constants/enums";
import { IDepartment, IDepartmentState } from "./department.types";

export const defaultDepartment: IDepartment = {
  department_uuid: "",
  department_name: null,
  department_head_uuid: "",
  department_head_name: "",
  department_details: "",
  billing_company_uuid: null,
  billing_company_name: null,
  status: "ACTIVE",
};

export const defaultDepartmentState: IDepartmentState = {
  departmentList: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  departmentData: {
    data: defaultDepartment,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
