import { combineReducers } from "redux";

import { IStoreState } from "./initialStoreState";
import { messageReducer } from "./messages/messagesReducer";
import { customerReducer } from "./customer/customerReducer";

import { commonReducer } from "./common/commonReducer";

import { UserProfileReducer } from "./UserProfileList/userProfileReducer";

import { opportunitesReducer } from "./opportunites/opportunitesReducer";
import { customizerReducer } from "./customizer/customizerReducer";
import { companyReducer } from "./company/companyReducer";
import { contactsReducer } from "./contacts/contactsReducer";
import { productsReducer } from "./products/productReducer";
import { quotesReducer } from "./quote/quoteReducer";
import { tasksBoardReducer } from "./taskBoard/taskBoardReducer";
import { securityReducer } from "./security/securityReducer";
import { renderingReducer } from "./rendering/renderingReducer";
import { dataManagementReducer } from "./DataManagement/dataManagementReducer";
import { costingSheetReducer } from "./costingSheet";
import { quotesApprovalReducer } from "./approval/quote";
import { commentReducer } from "./comments/comment.reducer";
import { projectsReducer } from "./projects/projectReducer";
import { scheduleTaskReducer } from "./scheduleTask/scheduleTaskReducer";
import { taskListReducer } from "./taskList/taskLIstReducer";
import { taskCategoryReducer } from "./taskCategory/taskCategoryReducer";
import { historyReducer } from "./history/HistoryReducer";
import { tasksCalenderReducer } from "./taskCalender/tasksCalenderReducer";
import { manageSiteReducer } from "./manageSite/manageSiteReducer";
import { projectTeamReducer } from "./projectTeam/projectTeamReducer";
import { expenseModuleReducer } from "./expenseModule";
import { departmentReducer } from "./department";
import { job_customerReducer } from "./job_customer";
import { job_contactsReducer } from "./job_contacts";
import { job_customerBranchReducer } from "./customerBranch";
import { customerDeliveryAddressReducer } from "./customerDeliveryAddress";
import { customerDispatchAddressReducer } from "./customerDispatchAddress";
import { customerVendorAttachmentReducer } from "./customerVendorAttachment";
import { bankDetailsReducer } from "./bankDetails/bankDetailsReducer";
import { billingCompanyReducer } from "./billingCompany";
import { vendorReducer } from "./vendor";
import { purchaseOrderReducer } from "./purchase-order";
import { quotationReducer } from "./quotation";
import { saleOrderReducer } from "./sale-order";
import { invoiceReducer } from "./invoice";
import { jobsReducer } from "./jobs";
import { userDocumentReducer } from "./userDocument/userDocumentReducer";

const appReducer = combineReducers({
  customer: customerReducer,

  userprofile: UserProfileReducer,

  opportunites: opportunitesReducer,

  customizerReducer: customizerReducer,
  company: companyReducer,
  contacts: contactsReducer,
  products: productsReducer,
  message: messageReducer,
  common: commonReducer,
  quotes: quotesReducer,
  costingSheet: costingSheetReducer,
  taskBoard: tasksBoardReducer,
  security: securityReducer,
  rendering: renderingReducer,
  dataManagement: dataManagementReducer,
  approvalQuotes: quotesApprovalReducer,
  comment: commentReducer,
  history: historyReducer,
  projects: projectsReducer,
  projectTeam: projectTeamReducer,
  scheduleTask: scheduleTaskReducer,
  taskList: taskListReducer,
  taskCategory: taskCategoryReducer,
  calender: tasksCalenderReducer,
  manageSite: manageSiteReducer,
  expenseModule: expenseModuleReducer,
  department: departmentReducer,
  job_customer: job_customerReducer,
  job_contacts: job_contactsReducer,
  job_customerBranch: job_customerBranchReducer,
  customerDeliveryAddress: customerDeliveryAddressReducer,
  customerDispatchAddress: customerDispatchAddressReducer,
  customerVendorAttachment: customerVendorAttachmentReducer,
  bankDetails: bankDetailsReducer,
  billingCompany: billingCompanyReducer,
  vendor: vendorReducer,
  purchase_order: purchaseOrderReducer,
  quotation: quotationReducer,
  sale_order: saleOrderReducer,
  invoices: invoiceReducer,
  jobs: jobsReducer,
  userDocument: userDocumentReducer,
});

export const rootReducer = (state: IStoreState | undefined, action: any) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
