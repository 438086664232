import { LoadState } from "../../constants/enums";
import { defaultInvoiceItem } from "../invoice";
import { IPurchaseOrder, IPurchaseOrderState } from "./purchase-order.types";

export const defaultPurchaseOrder: IPurchaseOrder = {
  purchase_order_uuid: null,
  purchase_order_type: null,
  purchase_order_no: "",
  job_name: "",
  job_order_no: "",
  job_uuid: "",
  purchase_order_date: null,
  challan_no: null,
  challan_date: null,
  lr_no: null,
  delivery_type: "",
  dispatch_through: null,
  transport_id: null,
  transport_doc_no: null,
  transport_doc_date: null,
  vehicle_no: null,
  place_of_supply: null,
  billing_company_uuid: null,
  billing_company_name: null,
  billing_company_branch_uuid: null,
  billing_company_branch_name: null,
  billing_company_branch_address_state: null,
  vendor_uuid: "",
  vendor_name: "",
  registration_type: "",
  vendor_gstin: "",
  vendor_address_line1: null,
  vendor_address_line2: null,
  vendor_address_city: null,
  vendor_address_state: null,
  vendor_address_pincode: null,
  vendor_address_country: null,
  delivery_address_name: null,
  delivery_address_line1: null,
  delivery_address_line2: null,
  delivery_address_city: null,
  delivery_address_state: null,
  delivery_address_pincode: null,
  delivery_address_country: null,
  project_manager_uuid: "",
  project_manager_name: "",
  contact_uuid: null,
  contact_name: null,
  phone_number: null,
  remark: null,
  invoice_items: [defaultInvoiceItem],
  total_amount: 0,
  is_round_off: 0,
  term_and_condition: null,
  total_amount_after_tax: 0,
  status: "PURCHASE_ORDER_REQUESTED",
  tcs: 0,
  tcs_amount: 0,
  discount: 0,
  discount_amount: 0,
  requested_by_uuid: "",
  additional_fields: [],
};

export const defaultPurchaseOrderState: IPurchaseOrderState = {
  purchase_order_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  single_purchase_order: {
    data: defaultPurchaseOrder,
    loading: LoadState.NotLoaded,
    error: null,
  },
  purchase_approval_List: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
