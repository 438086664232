import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IDepartment } from "./department.types";

// ********************** Fetch Department List********************** //
export const FETCH_DEPARTMENT_LIST_PROGRESS = "FETCH_DEPARTMENT_LIST_PROGRESS";
export const FETCH_DEPARTMENT_LIST_SUCCESS = "FETCH_DEPARTMENT_LIST_SUCCESS";
export const FETCH_DEPARTMENT_LIST_FAILED = "FETCH_DEPARTMENT_LIST_FAILED";

export const fetchDepartmentListProgress = () =>
  action(FETCH_DEPARTMENT_LIST_PROGRESS);
export const fetchDepartmentListSuccess = (
  data: IDepartment[],
  totalRecords: number,
) => action(FETCH_DEPARTMENT_LIST_SUCCESS, { data, totalRecords });
export const fetchDepartmentListFailed = () =>
  action(FETCH_DEPARTMENT_LIST_FAILED);

export const fetchDepartmentListAsync =
  (
    queryParams: IQueryParams,
    billing_company_uuid: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchDepartmentListProgress());
      let finalUrl = `/department/get-department${searchQuery}`;
      if (billing_company_uuid) {
        finalUrl += `&billing_company_uuid=${billing_company_uuid}`;
      }
      const res = await api.get(finalUrl);
      const data: IDepartment[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchDepartmentListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchDepartmentListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ********************** Fetch Single Department ********************** //
export const FETCH_DEPARTMENT_PROGRESS = "FETCH_DEPARTMENT_PROGRESS";
export const FETCH_DEPARTMENT_SUCCESS = "FETCH_DEPARTMENT_SUCCESS";
export const FETCH_DEPARTMENT_FAILED = "FETCH_DEPARTMENT_FAILED";

export const fetchDepartmentProgress = () => action(FETCH_DEPARTMENT_PROGRESS);
export const fetchDepartmentSuccess = (data: IDepartment) =>
  action(FETCH_DEPARTMENT_SUCCESS, { data });
export const fetchDepartmentFailed = (errorMessage: string) =>
  action(FETCH_DEPARTMENT_FAILED, { errorMessage });

export const fetchDepartmentAsync =
  (
    uuid: string,
    billing_company_uuid: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchDepartmentProgress());

      let url = `/department/get-department?department_uuid=${uuid}`;
      if (billing_company_uuid) {
        url += `&billing_company_uuid=${billing_company_uuid}`;
      }
      const res = await api.get(url);
      const data: IDepartment[] = res.data.data;

      if (data.length > 0) {
        dispatch(fetchDepartmentSuccess(data[0]));
      } else {
        dispatch(fetchDepartmentFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchDepartmentFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ********************** Upsert Department ********************** //
export const upsertDepartmentAsync =
  (
    data: IDepartment,
    onCallback: (isSuccess: boolean, customer?: IDepartment) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, rowId, ...payload } = data;

      dispatch(saveLoaderProgress());
      const res = await api.post("/department/upsert-department", {
        ...payload,
      });

      const result = res.data.data[0];

      let message = "Department created successfully!";
      onCallback(true, result);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ********************** Clear Department ********************** //

export const CLEAR_DEPARTMENT = "CLEAR_DEPARTMENT";
export const CLEAR_DEPARTMENT_STATE = "CLEAR_DEPARTMENT_STATE";

// Default Clear State

export const clearDepartment = () => action(CLEAR_DEPARTMENT);
export const clearDepartmentState = () => action(CLEAR_DEPARTMENT_STATE);
