import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IComment } from "./comments.types";

//****************************** Fetch Single Comment ******************************//
export const FETCH_COMMENT_LIST_PROGRESS = "FETCH_COMMENT_LIST_PROGRESS";
export const FETCH_COMMENT_LIST_SUCCESS = "FETCH_COMMENT_LIST_SUCCESS";
export const FETCH_COMMENT_LIST_FAILED = "FETCH_COMMENT_LIST_FAILED";

export const fetchCommentListProgress = () =>
  action(FETCH_COMMENT_LIST_PROGRESS);
export const fetchCommentListSuccess = (data: IComment[], count: number) =>
  action(FETCH_COMMENT_LIST_SUCCESS, { data, count });
export const fetchCommentListFailed = (errorMessage: string) =>
  action(FETCH_COMMENT_LIST_FAILED, { errorMessage });

export const fetchCommentListAsync =
  (
    moduleUUID: string,
    // value: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommentListProgress());
      const res = await api.get(
        `/comment/get-comment?module_uuid=${moduleUUID}`,
      ); // need to be replaced
      const data = res.data.data;
      dispatch(fetchCommentListSuccess(data, res.data.count));
    } catch (err: any) {
      dispatch(fetchCommentListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_COMMENT_LIST = "CLEAR_COMMENT_LIST";
export const clearCommentListAsync = () => action(CLEAR_COMMENT_LIST);

//****************************** Fetch Single Comment ******************************//
export const FETCH_SINGLE_COMMENT_PROGRESS = "FETCH_SINGLE_COMMENT_PROGRESS";
export const FETCH_SINGLE_COMMENT_SUCCESS = "FETCH_SINGLE_COMMENT_SUCCESS";
export const FETCH_SINGLE_COMMENT_FAILED = "FETCH_SINGLE_COMMENT_FAILED";

export const fetchSingleCommentProgress = () =>
  action(FETCH_SINGLE_COMMENT_PROGRESS);
export const fetchSingleCommentSuccess = (comment: IComment) =>
  action(FETCH_SINGLE_COMMENT_SUCCESS, { comment });
export const fetchSingleCommentFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_COMMENT_FAILED, { errorMessage });

export const fetchSingleCommentAsync =
  (projectId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleCommentProgress());
      const res = await api.get(
        `/enquiry/get-project?project_uuid=${projectId}`,
      ); // need to be replaced
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleCommentSuccess(data[0]));
      } else {
        dispatch(
          fetchSingleCommentFailed(
            "Unfortunately, there are no records available at the moment.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchSingleCommentFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_SINGLE_COMMENT = "CLEAR_SINGLE_COMMENT";
export const clearSingleCommentAsync = () => action(CLEAR_SINGLE_COMMENT);

//****************************** Insert/Update Single Comment ******************************//

export const upsertSingleCommentAsync =
  (
    comment: IComment,
    onCallback: (isSuccess: boolean, comment?: IComment) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, ...payload } = comment;

      const result = await api.post("/comment/upsert-comment", payload); // need to be replaced
      const responseComment = result.data?.data;
      let message = "Comment is saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        }),
      );
      onCallback(true, responseComment);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };
