import { Add, AppRegistration, BorderColor, Edit } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ReportedStatusRenderer,
  StatusRenderer,
} from "../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { StandardCard } from "../../../components/Cards";
import LoadingDialog from "../../../components/Dialogs/LoadingDialog";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { DataTable } from "../../../components/Table/DataTable";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import {
  CSVExportPlugin,
  RefreshPlugin,
  SearchPlugin,
} from "../../../components/Table/plugins";
import { PageContainer } from "../../../components/container/PageContainer";
import { CustomSelect } from "../../../components/formsComponents";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import { LoadState, MODULE_IDS } from "../../../constants/enums";
import {
  useDispatchWrapper,
  useLoggedInUserInfo,
  useRecordCounts,
} from "../../../hooks";
import { IBranch } from "../../../redux/DataManagement/dataManagement.types";
import { defaultUserBranch } from "../../../redux/DataManagement/defaultState";
import {
  clearExpenseState,
  convertFinanceToCleared,
  fetchDynamicApprovalHistoryAsync,
  fetchExpenseListAsync,
  IApprovalHistory,
  IExpense,
  upsertExcelPreviewAsync,
  upsertExpenseAsync,
} from "../../../redux/expenseModule";
import { IStoreState } from "../../../redux/initialStoreState";
import { RoleBasedCustomButton } from "../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";

import { green, red } from "@mui/material/colors";
import { useBillingCompanyFromStorage } from "../../dashboard/Dashboard/UserBillingInfo.hooks";
import {
  downloadSingleFileAsync,
  getFileExtensionType,
  IFileExtension,
} from "../../../components/FileUploadIcon/helpers";
import {
  closeLoaderWithMessage,
  openLoaderWithMessage,
} from "../../../redux/messages/messagesActions";
import { ViewImageDialog } from "../../../components/FileUploadIcon/ViewImageDialog";
import { CommentFieldRightPanel } from "../../Comments";

import { ExcelPreviewRightPanel } from "../../excelPreview/ExcelPreviewRightPanel";
import { useTableV2State } from "../../../components/TableV2/hooks/useTableV2State";
import { TABLE_CACHING_KEYS } from "../../../constants/constants";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../components/TableV2/interfaces/IDataTableV2Props";
import { DataTableV2 } from "../../../components/TableV2/DataTableV2";
import { createTabsWithRecordcounts } from "../../../helpers/createTabsWithRecordCounts";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../redux/common/commonActions";

export const ApprovalHistoryList: React.FC = () => {
  const dispatch = useDispatchWrapper();

  const { list, totalRecords, loading } = useSelector(
    (storeState: IStoreState) => storeState.expenseModule.ApprovalHistoryList,
  );

  const [saveLoading, setSaveLoading] = React.useState(false);
  // const [pagination, setPagination] = useState<{
  // 	pageNumber: number;
  // 	rowsPerPage: number;
  // }>({ pageNumber: 1, rowsPerPage: 10 });

  const [downloadLoading, setDownloadLoading] = React.useState(false);
  const [selectedRowsUuids, setSelectedRowsUuids] = React.useState<string[]>(
    [],
  );

  const billingComponyUuid = useBillingCompanyFromStorage();
  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  const {
    state: {
      dateState,
      searchState,
      tabs,
      columnsConfig,
      pagination,
      masterColumnResize,
    },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
    onPageChange,
    onRowsPerPageChange,
    onResizeMasterColumns,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "last28Days",
      selectedTab: "-1",
    },

    pagination: {
      pageNumber: 1,
      rowsInPerPage: 25,
      apiFetchRowCount: 26,
      extraFetchFactor: 1,
    },
    cachingFilters: {
      uniqueKey: TABLE_CACHING_KEYS.APPROVAL_EXPENSE_LIST,
    },
  });

  // handle delete/ active an item from list

  const branchListProps: IDataTableV2Props = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: pagination.rowsPerPage,
    isDataLoading: loading !== LoadState.Loaded,
    selectionMode: "none",
    uniqueRowKeyName: "expense_uuid",

    tableCommandBarProps: {
      preDefinedPlugins: {
        dateFilter: {
          state: dateState,
          onChange: setDateState,
        },
        search: {
          state: searchState,
          onChange: setSearchState,
          loadInitialFilterOncePopoverOpened: [
            {
              column: [""],
              logicalOperator: "AND",
              operator: "CONTAINS",
              value: "",
            },
          ],
        },
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: () => {
            fetchList();
          },
        },
      },
      leftItems: {
        customPlugins: [],
      },
      rightItems: {
        customPlugins: [],
      },
    },

    masterColumns: [
      {
        key: "current_level",
        headerName: "Current Level",
        fieldName: "current_level",

        renderType: DataTableV2RowRenderType.CHIP_SUCCESS,
      },
      {
        key: "previous_status",
        headerName: "Previous Status",
        fieldName: "previous_status",

        renderType: DataTableV2RowRenderType.CHIP_ERROR,
        // onRowCellRender: (value, row: IApprovalHistory) => {
        // 	return <StatusRenderer status={row.previous_status} />;
        // },
      },
      {
        key: "status",
        headerName: "Current Status",
        fieldName: "status",

        renderType: DataTableV2RowRenderType.CHIP_WARNING,
        // onRowCellRender: (value, row: IApprovalHistory) => {
        // 	return <StatusRenderer status={row.status} />;
        // },
      },
      {
        key: "next_status",
        headerName: "Next Status",
        fieldName: "next_status",

        renderType: DataTableV2RowRenderType.CHIP_SUCCESS,
        // onRowCellRender: (value, row: IApprovalHistory) => {
        // 	return <StatusRenderer status={row.next_status} />;
        // },
      },

      {
        key: "remark",
        headerName: "Remark",
        fieldName: "remark",

        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
      },
      {
        key: "create_ts",
        headerName: "Created Date",
        fieldName: "create_ts",
        renderType: DataTableV2RowRenderType.TEXT_DARK,

        enableSorting: true,
        onRowCellRender: (value, row: IExpense) => {
          return moment(row.create_ts).format("DD-MM-YYYY");
        },
      },
    ],

    rows: list,

    onPageChange: onPageChange,
    onRowsPerPageChange: onRowsPerPageChange,
    onSelection: (isAllSelected: boolean, rows: IExpense[]) => {
      const uuids = rows
        .map((row) => row.expense_uuid)
        .filter((uuid): uuid is string => uuid !== null);
      setSelectedRowsUuids(uuids);
    },
    masterColumnResize: {
      values: masterColumnResize,
      onResize: onResizeMasterColumns,
    },
  };

  const fetchList = () => {
    if (billingComponyUuid.customer_uuid) {
      dispatch(
        fetchDynamicApprovalHistoryAsync(
          pagination.pageNumber,
          pagination.rowsInPerPage,
          pagination.apiFetchRowCount,
          tabs.selectedTab,
          dateState.dates,
          searchState,
        ),
      );
    }
  };

  useEffect(() => {
    fetchList();
  }, [pagination]);

  useEffect(() => {
    return () => {
      dispatch(clearExpenseState());
    };
  }, []);

  return (
    <PageContainer
      title="Data management list"
      description="this is inner page"
    >
      <StandardCard>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{
            mx: 5,
            my: 2,
          }}
        >
          <Typography variant="h2" fontWeight={"700"}>
            Approval History List
          </Typography>
        </Box>
        <DataTableV2 {...branchListProps} />
        {saveLoading && <LoadingDialog open={saveLoading} />}

        <LoadingDialog open={downloadLoading} label="Please wait..." />
      </StandardCard>
    </PageContainer>
  );
};
